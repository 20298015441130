import * as PropTypes from 'prop-types';
import React, { Fragment, PureComponent } from 'react';
import { connect } from 'react-redux';
import Breadcrumb from '../Components/UI/Breadcrumb';
import Button from '../Components/UI/Button';
import { actions } from '../Redux/index';
import FilterDropdown from './FilterDropdown';
import IconLink from '../Components/UI/IconLink';
import { host } from '../Sagas/host';

const mapStateToProps = (state) => {
    return {
        currentUser: state.data.currentUser,
        locationFilter: state.data.filter.absencerecord.location,
        allLocations: state.data.allLocations,
        absenceView: state.absence.view,
        list: state.absence.list,
        listCount: state.absence.listCount,
        loadingList: state.absence.loadingList,
        userSearch: state.ui.userSearch,
        selectedLocation: state.data.filter.absencerecord.location,
        token: state.ui.token,
        absenceUserOverviewList: state.absence.absenceUserOverviewList,
        userCount: state.ui.userCount,
        yearFilter: state.data.yearFilter,
        contingents: state.data.contingents,
        withContingent: state.absence.withContingent,
        absenceSorting: state.absence.sorting,

    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateCalendar: (list) => dispatch(actions.Data.updateCalendar(list)),
        updateView: (value) => dispatch(actions.Ui.updateView(value)),
        updateCalendarData: (data) => dispatch(actions.Data.updateCalendarData(data)),
        updateAbsenceDate: (date) => dispatch(actions.Data.updateAbsenceDate(date)),
        updateFilter: (filter) => dispatch(actions.Data.updateFilter(filter)),
        loadCalendar: (list) => dispatch(actions.Data.loadCalendar(list)),
        updateAbsenceRequest: (absence) => dispatch(actions.Absence.updateAbsenceRequest(absence)),
        updatePrevView: (view) => dispatch(actions.Absence.updatePrevView(view)),
        loadAbsenceList: (list) => dispatch(actions.Absence.loadAbsenceList(list)),
        updateAbsenceList: (list) => dispatch(actions.Absence.updateAbsenceList(list)),
        updateAbsenceApproved: (absence) => dispatch(actions.Absence.updateAbsenceApproved(absence)),
        deleteAbsence: (absence) => dispatch(actions.Absence.deleteAbsence(absence)),
        updateUserSearch: (search) => dispatch(actions.Ui.updateUserSearch(search)),
        updateAbsenceUserOverviewList: (list) => dispatch(actions.Absence.updateAbsenceUserOverviewList(list)),
        loadAbsenceUserOverviewList: (from) => dispatch(actions.Absence.loadAbsenceUserOverviewList(from)),
        updateConfirmModal: (modal) => dispatch(actions.Ui.updateConfirmModal(modal)),
        updateYearFilter: (yearFilter) => dispatch(actions.Data.updateYearFilter(yearFilter)),
        updateLoadingList: (value) => dispatch(actions.Absence.updateLoadingList(value)),
        loadContingents: (value) => dispatch(actions.Data.loadContingents(value)),
        updateWithContingent: (value) => dispatch(actions.Absence.updateWithContingent(value)),
        updateAbsenceSorting: (value) => dispatch(actions.Absence.updateAbsenceSorting(value)),
    };
};

class Absence extends PureComponent {
    static propTypes = {
        updateView: PropTypes.func.isRequired,
        updateCalendar: PropTypes.func,
        calendar: PropTypes.array,
        allLocations: PropTypes.array,
        updateAbsenceDate: PropTypes.func.isRequired,
        updateFilter: PropTypes.func.isRequired,
        loadCalendar: PropTypes.func.isRequired,
        updateAbsenceRequest: PropTypes.func.isRequired,
        updatePrevView: PropTypes.func.isRequired,
        absenceView: PropTypes.string,
        loadAbsenceList: PropTypes.func.isRequired,
        updateAbsenceList: PropTypes.func.isRequired,
        list: PropTypes.array,
        listCount: PropTypes.number,
        loadingList: PropTypes.bool,
        updateAbsenceApproved: PropTypes.func,
        deleteAbsence: PropTypes.func,
        updateUserSearch: PropTypes.func.isRequired,
        currentUser: PropTypes.object,
        updateYearFilter: PropTypes.func,
        contingents: PropTypes.array,
        absenceSorting: PropTypes.array
    };

    componentDidMount() {
        this.props.updateUserSearch('')
        this.props.updateYearFilter('')
        this.props.loadCalendar(true)
    }

    can (...args) {
        return args.some( permission => this.props.currentUser.permissions.includes(permission))
    }

    deletedUser() {
        return <span style={{opacity: 0.3}}>Benutzer gelöscht</span>
    }

    absenceViewTitle() {
        const titles = {
            "1": "Offene Anträge",
            "2": "Akzeptierte Anträge",
            "3": "Vergangene Anträge",
            "5": "Abgelehnte Anträge",
            "4": "Urlaubsübersicht"
        };
        return titles[this.props.absenceView];
    }
    absenceViewIcon() {
        const icons = {
            "1": "fa fa-sticky-note",
            "2": "fa fa-check",
            "3": "fa fa-history",
            "4": "fa fa-calendar",
            "5": "fa fa-ban",
        };
        return icons[this.props.absenceView];
    }

    getLink()  {
        let location = '';
        if (this.props.selectedLocation.selectedId !== '') {
            location = this.props.selectedLocation.selectedId
        }else if (this.props.currentUser.location) {
            location = this.props.currentUser.currentLocation.id
        }
        const yearRegex = /(?:(?:18|19|20|21)[0-9]{2})/g;
        let yearFilter = '';
        if ((yearRegex.test(this.props.yearFilter))) {
            yearFilter = `?year=${this.props.yearFilter}`
        }
        let withContingent = ''
        if(this.props.withContingent && this.props.absenceView === '4') {
            withContingent =  yearFilter === '' ? '?contingent=1' : '&contingent=1'
        }
        return `${host}/exportabsence/${location}/${this.props.absenceView}${yearFilter}${withContingent}`

    }

    /**
     * @param userdata
     * @returns {*|Array}
     */
    getUserData(userdata) {
            return userdata.split(",");
    }

    getAdditionalInfo(absence) {
        if (absence.specialHoliday) {
            return ' \n Info: Sonderurlaub\n Grund: ' + absence.reason;
        } else if(absence.overtimeDeduction) {
            return ' \n Info: Abzug Überstunden\n Anzahl: ' + absence.overtime;
        }
        return '';
    }

    acceptAbsence(absence) {
        const confirmResult = window.confirm('Urlaubsantrag von ' + this.getUserData(absence.userData)[1] + ' '+ this.getUserData(absence.userData)[0]+ this.getAdditionalInfo(absence) + '\n genehmigen?');
        if (confirmResult === true) {
            this.props.updateAbsenceApproved({'id': absence.id, 'approved': true});
        }
    }

    declineAbsence(absence) {
        const confirmResult = window.confirm('Urlaubsantrag von "' + this.getUserData(absence.userData)[1] + ' '+ this.getUserData(absence.userData)[0]+ this.getAdditionalInfo(absence) + '\n ablehnen?');
        if (confirmResult === true) {
            this.props.updateAbsenceApproved({'id': absence.id, 'approved': false});
        }
    }

    deleteAbsence(absence) {
        this.props.updateConfirmModal({prop: 'visible', value: true})
        this.props.updateConfirmModal({prop: 'title', value: 'Urlaubsantrag löschen'})
        this.props.updateConfirmModal({prop: 'label', value: "löschen"})
        this.props.updateConfirmModal({prop: 'text', value: 'Urlaubsantrag von ' + this.getUserData(absence.userData)[1] + ' '+ this.getUserData(absence.userData)[0]+ this.getAdditionalInfo(absence) + '\n löschen?'})
        this.props.updateConfirmModal({prop: 'payload', value: {actionType: 'deleteAbsence', param: absence}})
    }

    notOwnOrAbleToEditOwn(absence) {
        if (this.can('urlaub_eigene_bearbeiten') && this.props.currentUser.id === absence.userId) {
            return true
        }
        return this.props.currentUser.id !== absence.userId
    }

    // absenceSort(a, b) {
    //     let sortProp = this.props.absenceSorting || ''
    //     if (a[sortProp] > b[sortProp]) {
    //         return 1;
    //     } else if(a[sortProp] < b[sortProp]) {
    //         return -1;
    //     }
    //     return 0;
    // }

    /**
     *
     * @param absence
     * @returns {*}
     */
    getReason(absence) {
        if (absence.specialHoliday) {
            return (<span title={absence.reason}> | Sonderurlaub</span>)
        } else if(absence.overtimeDeduction) {
            return (<span title={absence.ovetime}> | Überstunden {absence.overtime}</span>)
        }
        return '';
    }
    filterUserByFields(user) {
        if(user.firstName.toLowerCase().indexOf(this.props.userSearch.toLowerCase()) >= 0 ||
           user.lastName.toLowerCase().indexOf(this.props.userSearch.toLowerCase()) >= 0 ||
           user.locationLabel.toLowerCase().indexOf(this.props.userSearch.toLowerCase()) >= 0 ||
           user.personalNumber.toLowerCase().indexOf(this.props.userSearch.toLowerCase()) >= 0 ||
           user.team.toLowerCase().indexOf(this.props.userSearch.toLowerCase()) >= 0
        ) {
            return true
        }
        return false
    }


    renderView2and3() {
      if(this.props.loadingList !== true && this.props.absenceView !== "4") {
        return (
            this.props.list
            .filter((absence) => (absence.userData.toLowerCase().indexOf(this.props.userSearch.toLowerCase()) >= 0))
            .map((absence, index) => (
                <tr key={absence.id}>
                    <td><span>{this.getUserData(absence.userData)[1] || this.deletedUser()}</span></td>
                    <td><span>{this.getUserData(absence.userData)[0] || this.deletedUser()}</span></td>
                    <td><span>{this.getUserData(absence.userData)[3] || this.deletedUser()}</span></td>
                    <td><span>{this.getUserData(absence.userData)[2] || this.deletedUser()}</span></td>
                    <td><span>{absence.startDateFormated} - {absence.endDateFormated} {this.getReason(absence)}</span></td>
                    <td><span>{absence.createdAtFormated}</span></td>
                    <td><span>{absence.countDays}{' '}
                        {absence.exceeds[0] > absence.exceeds[1] && <i title={`Gesamtzahl der Urlaubstage in offenen Urlaubsanträgen (${absence.exceeds[0]}) übersteigt verbleibendes Kontingent (${absence.exceeds[1]}).`} style={{color: '#e20414'}} className="fa fa-exclamation-triangle"/>}
                    </span></td>
                    <td><span>{this.getUserData(absence.confirmedByData)[1]} {this.getUserData(absence.confirmedByData)[0]}</span></td>
                    <td><span>{absence.confirmedAtFormated}</span></td>
                    <td><span>{absence.approved === false ? (absence.declined ? 'abgelehnt' : 'offen') : 'akzeptiert'}</span></td>
                    <td>
                        <span>
                            { ["1","5"].includes(this.props.absenceView)
                            && this.can('urlaub_von_allen_sehen_und_bearbeiten',
                                'urlaub_von_allen_untergeordneten_gruppen_sehen_und_bearbeiten',
                                'urlaub_von_untergeordneten_aus_eigener_filiale_sehen_und_bearbeiten'
                            )
                            && this.notOwnOrAbleToEditOwn(absence) &&
                                    <i onClick={(e) => {
                                        e.preventDefault();
                                        this.acceptAbsence(absence);
                                    }}
                                    title="akzeptieren"
                                    className="fa fa-check" aria-hidden="true"/>
                            }

                            { ["1","2"].includes(this.props.absenceView)
                            && this.can('urlaub_von_allen_sehen_und_bearbeiten',
                                'urlaub_von_allen_untergeordneten_gruppen_sehen_und_bearbeiten',
                                'urlaub_von_untergeordneten_aus_eigener_filiale_sehen_und_bearbeiten'
                            )
                            && this.notOwnOrAbleToEditOwn(absence) &&
                                <i onClick={(e) => {
                                        e.preventDefault();
                                        this.declineAbsence(absence);
                                    }}
                                    className="fa fa-ban"
                                    title="ablehnen"
                                    aria-hidden="true"
                                />
                            }

                            { ["1","2","3","5"].includes(this.props.absenceView)
                                && this.can('urlaub_can_delete') &&
                                <i onClick={(e) => {
                                        e.preventDefault();
                                        this.deleteAbsence(absence);
                                    }}
                                    title="löschen"
                                    className="fa fa-trash" aria-hidden="true"/>
                            }

                        </span>
                    </td>
                </tr>
            ))

        )
    }
    }

    componentWillMount() {
        this.props.loadContingents('')

    }

    showSorting(column) {
        if(this.props.absenceSorting.includes(column)){
            return <i className="fa fa-angle-up" />
        }
        if(this.props.absenceSorting.includes(`-${column}`)){
            return <i className="fa fa-angle-down" />
        }

    }
    updateSorting(prop) {
        this.props.updateAbsenceSorting(prop)
        if (this.props.absenceView !== '4') {
            this.props.updateAbsenceList({ list: [], reset: true });
            this.props.loadAbsenceList({
                from: 0,
                view: this.props.absenceView,
                lazy: false,
            });
        }
    }


    render() {
        const emptyList = [1,2,3,4,5,6,7,8,9];
        const emptyColumns = this.props.absenceView === "4" ? [1,2,3,4,5,6,7,8] : [1,2,3,4,5,6,7];
        const emptyStyle = {minWidth: '100px'};
        return (
            <div id="main-side">
                <Breadcrumb path={['Urlaub', this.absenceViewTitle()]}>
                    {this.can('urlaub_nach_filiale_filtern') &&
                    <div className="visible btn-3">
                            <FilterDropdown
                                filter={this.props.locationFilter}
                                list={[{label: 'Alle', id: 'receivealllocations'}, ...this.props.allLocations.sort((a, b) => (a.label > b.label) ? 1 : -1)]}
                                itemIdProp={'id'}
                                itemLabelProp={'label'}
                                resetFilterLabel={true}
                                defaultSelectedLabel={'Ort wechseln'}
                                actionToExecute={this.props.absenceView !== '4' ? this.props.loadAbsenceList : this.props.loadAbsenceUserOverviewList}
                                paramsToExecute={this.props.absenceView !== '4' ? { from: 0, view: this.props.absenceView } : '0'}
                                secondActionToExecute={this.props.absenceView !== '4' ? this.props.updateAbsenceList : null}
                                secondParamsToExecute={{ list: [], reset: true }}
                            />
                    </div>}
                    {this.can('urlaub_nach_mitarbeiter_filtern') &&
                    <div className="btn-3 absence-date">
                        <input type="text" name='test'
                            value={this.props.userSearch || ''}
                            placeholder={'Suche Mitarbeiter'}
                            onChange={e => {
                                this.props.updateUserSearch(e.target.value);
                                this.props.loadAbsenceList({
                                    'from': this.props.list.length,
                                    view: this.props.absenceView,
                                    lazy: true})
                            }}
                        />
                    </div>}
                    {this.props.absenceView !== '4' &&
                        <div className="btn-3 absence-date year-filter">
                            <input type="text"
                                   value={this.props.yearFilter || ''}
                                   placeholder={'Jahr'}
                                   onChange={e => {
                                       this.props.updateYearFilter(e.target.value);
                                       const yearRegex = /(?:(?:18|19|20|21)[0-9]{2})/g;
                                       if ((yearRegex.test(e.target.value))) {
                                           this.props.updateAbsenceList({list: [], reset: true})
                                           this.props.updateLoadingList(true);
                                           this.props.loadAbsenceList({
                                               'from': 0,
                                               view: this.props.absenceView,
                                                lazy: true})
                                           // if (this.props.absenceView === '4') {
                                           //     this.props.loadAbsenceUserOverviewList('0')
                                           // } else {
                                           //
                                           // }

                                       }

                                   }}
                            />
                        </div>
                    }


                    {this.can('urlaub_antrag_stellen') && <Button label='Neuer Urlaubsantrag' onClick={() => {
                            this.props.updateAbsenceRequest({ prop: 'userId', val: this.props.currentUser.id });
                            this.props.updateView('AbsenceRequestForm');
                            this.props.updatePrevView('Absence');
                        }}
                    />}
                </Breadcrumb>

                <main>
                    <div className="content-wrapper margin-top">
                        <div className="content-wrapper-box">
                            <div className="grid-wrapper">
                                <div className="small-12 medium-12 large-12">
                                    <div className="site-wrapper">
                                        <div className="headline">

                                            <span><i className={this.absenceViewIcon()} aria-hidden="true"></i> {this.absenceViewTitle()} </span>
                                            {this.props.absenceView === '4' &&
                                                <>
                                                <span>&nbsp;(mit Vorjahren)</span>
                                            <label className="switch switch-overview">
                                                <input checked={this.props.withContingent}
                                                       onChange={() => this.props.updateWithContingent(!this.props.withContingent)}
                                                       type="checkbox"/>
                                                <span className="slider round"></span>

                                            </label></>

                                            }
                                        </div>
                                        <div className="overflow-x">
                                            <table>
                                                <tbody>
                                                <tr>
                                                    <th onClick={() => this.updateSorting('usr.lastName')}><span>Name {this.showSorting('usr.lastName')}</span></th>
                                                    <th onClick={() => this.updateSorting('usr.firstName')}><span>Vorname {this.showSorting('usr.firstName')}</span></th>
                                                    <th onClick={() => this.updateSorting('usr.personalNumberAsInt')}><span>Personalnummer {this.showSorting('usr.personalNumberAsInt')}</span></th>
                                                    {
                                                        this.props.withContingent && this.props.absenceView === "4" &&
                                                        <th><span>Jahr</span></th>
                                                    }
                                                    <th onClick={() => this.updateSorting('usr.location.name')}><span>Standort {this.showSorting('usr.location.name')}</span></th>


                                                    {this.props.absenceView !== "4" &&
                                                        <th onClick={() => this.updateSorting('startDate')}><span>Zeitraum {this.showSorting('startDate')}</span></th>
                                                    }
                                                    {this.props.absenceView === "4" &&
                                                        <th onClick={() => this.updateSorting('availableHolidays')}><span>Urlaubstage gesamt {this.showSorting('availableHolidays')}</span></th>
                                                    }
                                                    {
                                                        this.props.absenceView !== "4" &&
                                                        <Fragment>
                                                            <th onClick={() => this.updateSorting('createdAt')}><span>Erstellt am {this.showSorting('createdAt')}</span></th>
                                                            <th onClick={() => this.updateSorting('countDays')}><span>Anzahl Urlaubstage {this.showSorting('countDays')}</span></th>
                                                            <th onClick={() => this.updateSorting('confirmedBy')}><span>Bearbeitet von {this.showSorting('confirmedBy')}</span></th>
                                                            <th onClick={() => this.updateSorting('confirmedAt')}><span>Bearbeitet am {this.showSorting('confirmedAt')}</span></th>
                                                        </Fragment>
                                                    }
                                                    {this.props.absenceView !== "4" &&
                                                        <th onClick={() => this.updateSorting('approved')}><span>Status {this.showSorting('approved')}</span></th>
                                                    }
                                                    {this.props.absenceView !== "4" &&
                                                        <th><span>Aktionen</span></th>
                                                    }
                                                    {this.props.absenceView === "4" &&
                                                        <th onClick={() => this.updateSorting('holidaysTakenThisYear')}><span>Genutzte Tage {this.showSorting('holidaysTakenThisYear')}</span></th>
                                                    }
                                                    {this.props.absenceView === "4" &&
                                                        <th onClick={() => this.updateSorting('holidaysPlannedThisYear')}><span>Geplante Tage {this.showSorting('holidaysPlannedThisYear')}</span></th>
                                                    }
                                                    {this.props.absenceView === "4" &&
                                                        <th onClick={() => this.updateSorting('toPlan')}><span>Offene Tage {this.showSorting('toPlan')}</span></th>
                                                    }
                                                    {this.props.absenceView === "4" &&
                                                        <th onClick={() => this.updateSorting('holidaySurplusPreviousYear')}><span>Resturlaub Vorjahr {this.showSorting('holidaySurplusPreviousYear')}</span></th>
                                                    }
                                                    {this.props.absenceView === "4" &&
                                                        <th onClick={() => this.updateSorting('holidayContingent')}><span>Urlaubstage laut Vertrag {this.showSorting('holidayContingent')}</span></th>
                                                    }
                                                </tr>
                                                {this.props.loadingList === true &&
                                                    emptyList.map((item, index) => (
                                                        <tr key={index}>
                                                            {emptyColumns.map(column => (
                                                                <td key={`absenceViewColumn${column}`}>
                                                                    <div className="default-list-item">
                                                                        <span style={emptyStyle}></span>
                                                                    </div>
                                                                </td>
                                                            ))}
                                                        </tr>
                                                    ))
                                                }
                                                {this.renderView2and3()}
                                                 { this.props.loadingList !== true && this.props.absenceView === "4" &&
                                                        this.props.absenceUserOverviewList
                                                            .sort((a, b) => {
                                                                     let sortProp = this.props.absenceSorting[0] || 'teamSortIndex'
                                                                     const sortPropWithoutSign = sortProp.replace('-', '')
                                                                         .replace('usr.', '')
                                                                         .replace('usr.location.name', 'locationLabel');
                                                                     let propa = a[sortPropWithoutSign];
                                                                     let propb = b[sortPropWithoutSign];
                                                                     if(['availableHolidays','holidaysTakenThisYear','holidaysPlannedThisYear','holidaySurplusPreviousYear','holidayContingent'].includes(sortPropWithoutSign)) {
                                                                         propa = Number(propa);
                                                                         propb = Number(propb);
                                                                     }
                                                                     if(['toPlan'].includes(sortPropWithoutSign)) {
                                                                         propa = Number(a.availableHolidays) - (Number(a.holidaysTakenThisYear) + Number(a.holidaysPlannedThisYear));
                                                                         propb = Number(b.availableHolidays) - (Number(b.holidaysTakenThisYear) + Number(b.holidaysPlannedThisYear));
                                                                     }
                                                                     if (propa > propb) {
                                                                     return sortProp[0] === '-' ? -1 : 1;
                                                                 } else if(propa < propb) {
                                                                     return sortProp[0] === '-' ? 1 : -1;
                                                                 }
                                                                    return 0;
                                                            })
                                                            .filter((user) => this.filterUserByFields(user)).map(user => (
                                                               <React.Fragment key={user.id}>
                                                                    <tr key={user.id}>
                                                                        <td ><span>{user.lastName}</span></td>
                                                                        <td><span>{user.firstName}</span></td>
                                                                        <td><span>{user.personalNumber}</span></td>
                                                                        {
                                                                            this.props.withContingent &&
                                                                            <td><span>{new Date().getFullYear()}</span></td>
                                                                        }

                                                                        <td><span>{user.locationLabel}</span></td>
                                                                        <td><span>{user.availableHolidays}</span></td>
                                                                        <td><span>{user.holidaysTakenThisYear}</span></td>
                                                                        <td><span>{user.holidaysPlannedThisYear}</span></td>
                                                                        <td><span>{Number(user.availableHolidays) - (Number(user.holidaysTakenThisYear) + Number(user.holidaysPlannedThisYear))}</span></td>
                                                                        <td><span>{user.holidaySurplusPreviousYear}</span></td>
                                                                        <td><span>{user.holidayContingent}</span></td>
                                                                    </tr>
                                                                   {this.props.withContingent && this.props.contingents
                                                                       .sort((a,b) => a.year > b.year)
                                                                       .filter(contingent => user.id === contingent.userId).map(contingent =>(
                                                                       <tr key={contingent.id}>
                                                                           <td><span>------</span></td>
                                                                           <td><span>------</span></td>
                                                                           <td><span>------</span></td>
                                                                           <td><span>{contingent.year}</span></td>
                                                                           <td><span>{user.locationLabel}</span></td>
                                                                           <td><span>{contingent.available}</span></td>
                                                                           <td><span>{contingent.approvedHolidays}</span></td>
                                                                           <td><span>0</span></td>
                                                                           <td><span>{Number(contingent.available) - Number(contingent.approvedHolidays)}</span></td>
                                                                           <td><span>{Math.abs(Number(contingent.vacationClaim) - Number(contingent.available))}</span></td>
                                                                           <td><span>{contingent.vacationClaim}</span></td>
                                                                       </tr>
                                                                   ))}

                                                               </React.Fragment>
                                                            ))

                                                }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {this.props.listCount > this.props.list.length && this.props.absenceView !== "4" &&
                    <div className="content-wrapper inner-ver">
                        <div className="content-wrapper-box">
                            <div className="grid-wrapper">
                                <div className="small-12 medium-12 large-12">
                                    <Button
                                        icon="refresh"
                                        label="Weitere Einträge anzeigen"
                                        inverted
                                        onClick={() => this.props.loadAbsenceList({
                                            'from': this.props.list.length,
                                            view: this.props.absenceView,
                                            lazy: true,
                                        })}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>}

                    {this.can('urlaub_exportieren') &&
                    <div className="content-wrapper inner-ver">
                        <div className="content-wrapper-box">
                            <div className="grid-wrapper">
                                <div className="small-12 medium-12 large-12">
                                    <div>
                                        <br/>
                                        <form action={this.getLink()}
                                                method="post"
                                                ref={(ref) => {
                                                    this.downloadForm = ref;
                                                }}>
                                            <input type="hidden" name="authTok"
                                                    value={'Bearer ' + this.props.token}
                                            />
                                        </form>
                                        <IconLink type="download"  onClick={() => this.downloadForm.submit()}> Export XLSX</IconLink>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    }
                </main>


                <footer id="footer-main">
                </footer>
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Absence);
