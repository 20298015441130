import * as PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import {actions} from "../Redux";

class Rating extends React.Component {
    static propTypes = {
        trainingToEdit: PropTypes.object,
    };

    render() {
        return (
            <div className="grid-wrapper" key={`ratingIndex${this.props.ratingIndex}`}>
                <div className="small-12 medium-6 large-6">
                    <div className="group">
                        <input type="text" value={this.props.trainingToEdit.ratings[this.props.ratingIndex]['label'] || ''} onChange={(e) => this.props.updateTrainingToEdit({prop: 'ratings.'+this.props.ratingIndex+'.label', val: e.target.value})} />
                        <label>Bewertung Frage</label>
                    </div>
                </div>
                <div className="small-12 medium-4 large-4">
                        <div className="check-list" style={{boxShadow: 'none'}}>
                            <div className="check-list-main">
                                <div className="checkbox-wrapper-rating" style={{backgroundColor: '#fff'}}>
                                    <label>
                                        <span className={this.props.trainingToEdit.ratings[this.props.ratingIndex].type === 'Sternbewertung' ? 'boxChecked' : 'boxUnchecked'} onClick={() => {

                                            this.props.updateTrainingToEdit({
                                                prop: 'ratings.'+this.props.ratingIndex+'.type',
                                                val: this.props.trainingToEdit.ratings[this.props.ratingIndex].type === 'Sternbewertung' ? 'Frage' : 'Sternbewertung'
                                            })
                                        }} />
                                    </label>
                                    <div className="checkbox-text">
                                        <span className={'prim-text'}>Sternbewertung</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                </div>
                <div className="small-12 medium-2 large-2">
                    <div className="btn btn-2">
                    {this.props.trainingToEdit.ratings.length > 1 &&
                            <span className="rating-button" onClick={(e) => {e.preventDefault();
                                    this.props.deleteRating(this.props.ratingIndex)
                                }}><i className="fa fa-minus-circle"></i>
                            </span>
                    }

                        <span className="rating-button" onClick={(e) => {e.preventDefault(); this.props.addNewRating(true)}}><i className="fa fa-plus-circle"></i>
                        </span>
                    </div>

                </div>

            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    trainingToEdit: state.training.trainingToEdit,
});

const mapDispatchToProps = (dispatch) => {
    return {
        updateTrainingToEdit: (training) => dispatch(actions.Training.updateTrainingToEdit(training)),
        addNewRating: (value) => dispatch(actions.Training.addNewRating(value)),
        deleteRating: (value) => dispatch(actions.Training.deleteRating(value)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Rating);
