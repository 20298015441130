import ArticleSagas from './ArticleSaga';
import AuthenticateSagas from './AuthenticateSaga';
import CalendarSagas from './CalendarSaga';
import CategorySagas from './CategorySaga';
import ClaimSagas from './ClaimsSaga';
import DocumentSagas from './DocumentSaga';
import GroupSagas from './GroupSaga';
import LocationSagas from './LocationSaga';
import LoginLogSagas from './LoginLogSaga';
import ManufacturerSagas from './ManufacturerSaga';
import NewsSagas from './NewsSaga';
import TaskSagas from './TaskSaga';
import UserSagas from './UserSaga';
import TrainingSagas from './TrainingSaga';
//import SickReportSagas from './SickReportSaga';
import AdvertisingSagas from './AdvertisingSaga';
import SeenSagas from './SeenSaga';
import locationSpecialAppointmentSagas from './LocationSpecialAppointmentSaga';
import locationSortimentHistorySagas from './LocationSortimentHistorySaga';
import colorSagas from './ColorSaga';
import settingsSagas from 'Sagas/SettingsSaga'
import teamSagas from 'Sagas/TeamSaga'
import GlobalSettingsSaga from "./GlobalSettingsSaga";
import claimDropdownManufacturerSagas from "./ClaimDropdownManufacturerSaga";
import claimDropdownQualitySagas from "./ClaimDropdownQualitySaga";
import claimDropdownReasonSagas from "./ClaimDropdownReasonSaga";
import likesAndCommentsSagas from "./LikesAndCommentsSaga";

export default [].concat(
    ArticleSagas,
    AuthenticateSagas,
    CalendarSagas,
    CategorySagas,
    ClaimSagas,
    DocumentSagas,
    GroupSagas,
    LocationSagas,
    LoginLogSagas,
    ManufacturerSagas,
    NewsSagas,
    TaskSagas,
    UserSagas,
    TrainingSagas,
   // SickReportSagas,
    AdvertisingSagas,
    SeenSagas,
    locationSpecialAppointmentSagas,
    locationSortimentHistorySagas,
    colorSagas,
    settingsSagas,
    teamSagas,
    GlobalSettingsSaga,
    claimDropdownManufacturerSagas,
    claimDropdownQualitySagas,
    claimDropdownReasonSagas,
    likesAndCommentsSagas,
);
