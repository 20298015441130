import { put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import getHeaders from 'Sagas/headers';
import { actions, actionTypes, selectors } from '../Redux/index';
import { host } from './host';
import {delay} from "redux-saga";

function* trainingSaga() {
    yield takeEvery([actionTypes.Training.LOAD_TRAINING_LIST], function* (action) {
        try {
            yield put(actions.Training.updateLoadingAnimation(true));
            const token = yield select(selectors.Ui.token);
            const training = yield select(selectors.Training.training);
            const data = yield select(selectors.Data.data);
            const headers = getHeaders(token);

            const yearFilter = data.yearFilter;
            const yearRegex = /(?:(?:18|19|20|21)[0-9]{2})/g;
            const old = action.payload.list.old || false;
            const from = action.payload.list.from || 0;
            let appendYear = '';

            if ((yearRegex.test(yearFilter))) {
                appendYear = `?year=${yearFilter}`
            }
            //
            // const old = action.payload.list.old || false;
            // const from = action.payload.list.from || 0;

            const result = yield fetch(host + '/training/list/'+from+'/'+old+appendYear, {
                method: "GET",
                headers,
            });
            yield put(actions.Ui.checkLogin(result.status));
            if (result.status !== 406 && result.status !== 408) {
                const list = yield result.json();
                let path1 = 'oldList';
                let path2 = 'oldListCount';
                if (!old) {
                    path1 = 'list';
                    path2 = 'listCount';
                }
                if (from !== 0) {
                    yield put(
                        actions.Training.updateTrainingList({
                            path: path1,
                            list: [...training[path1], ...list.trainings],
                        })
                    );
                } else {
                    yield put(actions.Training.updateTrainingList({'path': path1, 'list': list.trainings}));
                }
                yield put(actions.Training.updateListCount({'path': path2, 'count': list.count}));
                yield put(actions.Ui.updateCountSeen({page: 'Trainings', value: list.new}))
                yield put(actions.Training.updateLoadingAnimation(false));
            }
        } catch (e) {
            console.log("ERROR", e);
        }
    });
}


function* saveTrainingSaga() {
    yield takeLatest(actionTypes.Training.SAVE_TRAINING, function* (action) {
        try {
            const token = yield select(selectors.Ui.token);
            const trainingData = yield select(selectors.Training.training);
            const dropdown = yield select(selectors.Data.dropdown);
            const headers = getHeaders(token);
            trainingData.trainingToEdit.contactPerson = dropdown.training.user.selectedId
            trainingData.trainingToEdit.category = dropdown.training.category.selectedId
            let training = {"training": trainingData.trainingToEdit};

            if (action.payload.value === 'add') {
                const result = yield fetch(host+'/training', {
                    method: "POST",
                    headers,
                    body: JSON.stringify(training)});

                yield put(actions.Ui.checkLogin(result.status));
                if (result.status !== 406 && result.status !== 408) {
                    yield put(actions.Training.loadTrainingList({'old': false, 'from': 0}));
                    yield put(actions.Training.updateTrainingToEdit({prop: 'resetObj', val: ''}));
                    yield put(actions.Data.updateModal({path: 'training.training', prop: 'view', val: false}));
                    yield put(actions.Data.updateDropdown({dropdown: 'dropdown.training.user', prop:'selectedId', val:''}));
                    yield put(actions.Data.updateDropdown({dropdown: 'dropdown.training.user', prop:'selectedLabel', val:''}));
                    yield put(actions.Data.updateDropdown({dropdown: 'dropdown.training.category', prop:'selectedLabel', val:''}));
                    yield put(actions.Data.updateDropdown({dropdown: 'dropdown.training.category', prop:'selectedId', val:''}));
                }
            } else {
                const id = trainingData.trainingToEdit.id;
                delete training.training.id;
                if (action.payload.value === 'reschedule') {
                    training['move'] = true;
                }
                const result = yield fetch(host+'/training/'+id, { method: "PUT", headers, body: JSON.stringify(training)});
                yield put(actions.Ui.checkLogin(result.status));
                if (result.status !== 406 && result.status !== 408) {
                    yield put(actions.Training.loadTrainingList({'old': false, 'from': 0}));
                    yield put(actions.Training.updateTrainingToEdit({prop: 'resetObj', val: ''}));
                    yield put(actions.Data.updateModal({path: 'training.training', prop: 'view', val: false}));
                    yield put(actions.Data.updateModal({path: 'training.reschedule', prop: 'view', val: false}));
                    yield put(actions.Data.updateDropdown({dropdown: 'dropdown.training.user', prop:'selectedId', val:''}));
                    yield put(actions.Data.updateDropdown({dropdown: 'dropdown.training.user', prop:'selectedLabel', val:''}));
                    yield put(actions.Data.updateDropdown({dropdown: 'dropdown.training.category', prop:'selectedLabel', val:''}));
                    yield put(actions.Data.updateDropdown({dropdown: 'dropdown.training.category', prop:'selectedId', val:''}));
                }
            }

        } catch (e) {
            console.log("ERROR", e);
        }
    });
}


function* removeTrainingSaga() {
    yield takeLatest(actionTypes.Training.DELETE_TRAINING, function* (action) {
        try {
            const token = yield select(selectors.Ui.token);
            const headers = getHeaders(token);

            const result = yield fetch(host + '/training/' + action.payload.training.training.id, {  method: 'DELETE', headers});
            yield put(actions.Ui.checkLogin(result.status));
        } catch (e) {
            console.log("Error", e);
        }
    });
}


function* registerForTrainingSaga() {
    yield takeLatest(actionTypes.Training.REGISTER_FOR_TRAINING, function* (action) {
        try {
            const token = yield select(selectors.Ui.token);
            const trainingData = yield select(selectors.Training.training);
            const currentTraining = trainingData.currentTraining;
            const dataData = yield select(selectors.Data.data);
            const headers = getHeaders(token);
            const forceRegister = trainingData.forceRegister !== '' ? '?force=1': '';

            if(Object.prototype.hasOwnProperty.call(action.payload.training,'directRegistration')) {
                const result = yield fetch(host+'/training/registerdirect'+forceRegister, { method: "POST", headers, body: JSON.stringify({...action.payload})});

                yield put(actions.Ui.checkLogin(result.status));
                if (result.status !== 406 && result.status !== 408) {
                    yield put(actions.Training.loadTrainingList({'old': false, 'from': 0}));
                    yield put(actions.Data.updateDropdown({dropdown: 'dropdown.training.user', prop:'selectedId', val:''}));
                    yield put(actions.Data.updateDropdown({dropdown: 'dropdown.training.user', prop:'selectedLabel', val:''}));
                    yield put(actions.Training.updateCurrentTraining(null));
                    yield put(actions.Data.updateModal({path: 'training.addRegistration', prop: 'view', val: false}));
                    const {registerStatus, registerMessage} = yield result.json();
                    yield put(actions.Training.updateRegisterStatus(registerStatus));
                    yield put(actions.Training.updateRegisterMessage(registerMessage));
                    if(registerStatus === 400) {
                        yield put(actions.Training.updateForceRegister(action.payload.training.id));
                        yield put(actions.Data.updateDropdown({dropdown: 'dropdown.training.user', prop:'selectedId', val: dataData.dropdown.training.user.selectedId}));
                        yield put(actions.Data.updateDropdown({dropdown: 'dropdown.training.user', prop:'selectedLabel', val: dataData.dropdown.training.user.selectedLabel}));
                        yield put(actions.Training.updateCurrentTraining(currentTraining));

                    }
                }
            } else {
                const result = yield fetch(host + '/training/register/' + action.payload.training.id + forceRegister, {  method: 'GET', headers});
                yield put(actions.Ui.checkLogin(result.status));
                if (result.status !== 406 && result.status !== 408) {
                    const {registerStatus, registerMessage} = yield result.json();
                    yield put(actions.Training.updateRegisterStatus(registerStatus));
                    yield put(actions.Training.updateRegisterMessage(registerMessage));
                    if(registerStatus === 400) {
                        yield put(actions.Training.updateForceRegister(action.payload.training.id));
                    }
                    yield put(actions.Training.loadTrainingList({'old': false, 'from': 0}));

                }
            }



        } catch (e) {
            console.log("Error", e);
        }
    });
}

function* approveRegistrationForTrainingSaga() {
    yield takeLatest(actionTypes.Training.APPROVE_REGISTRATION_FOR_TRAINING, function* (action) {
        try {
            const token = yield select(selectors.Ui.token);
            const headers = getHeaders(token);

            const result = yield fetch(host + '/training/register/approve/' + action.payload.appointment.appointment.id, {  method: 'GET', headers});
            yield put(actions.Ui.checkLogin(result.status));


        } catch (e) {
            console.log("Error", e);
        }
    });
}
function* addValuationSaga() {
    yield takeLatest(actionTypes.Training.ADD_VALUATION, function* (action) {
        try {
            const token = yield select(selectors.Ui.token);
            const headers = getHeaders(token);

            let valuation = {"valuation": action.payload.valuation};
            valuation.valuation.training = action.payload.valuation.training.id;
            const result = yield fetch(host+'/valuation', { method: "POST", headers, body: JSON.stringify(valuation)});
            yield put(actions.Ui.checkLogin(result.status));
            if (result.status !== 406 && result.status !== 408) {
                yield put(actions.Training.updateValuation({prop: 'resetObj', val: ''}));
                yield put(actions.Data.updateModal({path: 'training.valuation', prop: 'view', val: false}));
            }

        } catch (e) {
            if (e.status === 409) {
                yield put(actions.Training.updateValuation({prop: 'resetObj', val: ''}));
                yield put(actions.Data.updateModal({path: 'training.valuation', prop: 'view', val: false}));
            }
        }
    });
}
function* declineRegistrationForTrainingSaga() {
    yield takeLatest(actionTypes.Training.DECLINE_REGISTRATION_FOR_TRAINING, function* (action) {
        try {
            const token = yield select(selectors.Ui.token);
            const headers = getHeaders(token);

            const result = yield fetch(host + '/training/register/decline/' + action.payload.appointment.appointment.id, {  method: 'GET', headers});
            yield put(actions.Ui.checkLogin(result.status));
            if (result.status !== 406 && result.status !== 408) {
                yield put(actions.Training.loadTrainingList({'old': false, 'from': 0}));
            }

        } catch (e) {
            console.log("Error", e);
        }
    });
}

function* loadAllUsersForTrainingSaga() {
    yield takeLatest(actionTypes.Training.LOAD_ALL_USERS_FOR_TRAINING, function* () {
        try {
            const token = yield select(selectors.Ui.token);
            const headers = getHeaders(token);
            const result = yield fetch(host + '/user/listallfortraining', {
                method: "GET",
                headers: headers,
            });
            yield put(actions.Ui.checkLogin(result.status));
            if (result.status !== 406 && result.status !== 408) {
                const list = yield result.json();
                yield put(actions.Training.updateAllUsersForTraining(list));
            }
        } catch (e) {
            console.log("ERROR", e);
        }
    });
}

function* loadUserTrainingsSaga() {
    yield takeLatest(actionTypes.Training.LOAD_USER_TRAININGS, function* () {
        try {
            const token = yield select(selectors.Ui.token);
            const headers = getHeaders(token);
            const result = yield fetch(host + '/usertrainings', {
                method: "GET",
                headers: headers,
            });
            yield put(actions.Ui.checkLogin(result.status));
            if (result.status !== 406 && result.status !== 408) {
                const list = yield result.json();
                yield put(actions.Training.updateUserTrainings(list.trainings));
            }
        } catch (e) {
            console.log("ERROR", e);
        }
    });
}


function* overviewListSaga() {
    yield takeLatest(actionTypes.Training.LOAD_OVERVIEW_LIST, function* (action) {
        try {
            yield delay(300)
            yield put(actions.Training.updateLoadingAnimation(true));
            const token = yield select(selectors.Ui.token);
            const stateData = yield select(selectors.Data.data);
            let yearFilter = stateData.yearFilter ? `?year=${stateData.yearFilter}`: '';

            let location = '';
            if (stateData.filter.training.location.selectedId !== '') {
                location = stateData.filter.training.location.selectedId;
            }else if (stateData.currentUser.currentLocation) {
                location = stateData.currentUser.currentLocation.id;
            }

            const headers = getHeaders(token);
            let uri = host+'/trainingoverview/'+location+yearFilter;
            const result = yield fetch(uri, { method: "GET", headers});
            yield put(actions.Ui.checkLogin(result.status));
            if (result.status !== 406 && result.status !== 408) {
                const list = yield result.json();
                yield put(actions.Training.updateOverviewList(list));
                yield put(actions.Training.updateLoadingAnimation(false));
            }
        } catch (e) {
            console.log("Error, loadTrainingOverview", e);
        }
    });
}


export default [trainingSaga, saveTrainingSaga, removeTrainingSaga, registerForTrainingSaga, approveRegistrationForTrainingSaga, declineRegistrationForTrainingSaga, addValuationSaga, loadAllUsersForTrainingSaga, loadUserTrainingsSaga, overviewListSaga];
