import * as moment from 'moment';

export const roles = {
    Admin: 'Laminatdepot.Webapp:Admin',
    Personalleitung: 'Laminatdepot.Webapp:Personalleitung',
    Buchhaltung: 'Laminatdepot.Webapp:Buchhaltung',
    Vertriebsleitung: 'Laminatdepot.Webapp:Vertriebsleitung',
    Vertrieb: 'Laminatdepot.Webapp:Vertrieb',
    Zentrallagerleitung: 'Laminatdepot.Webapp:Zentrallagerleitung',
    Verwaltung: 'Laminatdepot.Webapp:Verwaltung',
    Filialleitung: 'Laminatdepot.Webapp:Filialleitung',
    Verkauf: 'Laminatdepot.Webapp:Verkauf',
    Azubi: 'Laminatdepot.Webapp:Azubi',
    Aushilfe: 'Laminatdepot.Webapp:Aushilfe',
    Bodenleger: 'Laminatdepot.Webapp:Bodenleger',
    Franchise: 'Laminatdepot.Webapp:Franchise',
    Franchise_Wischniewski: 'Laminatdepot.Webapp:Franchise_Wischniewski',
    Franchise_Brünger: 'Laminatdepot.Webapp:Franchise_Bruenger',
    Ausgeblendet: 'Laminatdepot.Webapp:Deactivated',
    Lagerist: 'Laminatdepot.Webapp:Lagerist',
};

export function hasPermission(currentUser, ...requiredRoles) {
    if (!currentUser || !currentUser.roleIdentifier) {
        return false;
    }
    if (requiredRoles.length === 0) {
        console.warn('hasPermission: Missing required roles.');
        return false;
    }
    if (currentUser.roleIdentifier === roles.Admin) {
        return true;
    }
    return requiredRoles.flat(1).some((role) => currentUser.roleIdentifier === role);
}

export function getTaskPriorityClass(task) {
    let cls = 'checkbox-wrapper';
    if (Object.prototype.hasOwnProperty.call(task, 'priority')) {
        if (task.priority === 1) {
            cls += ' yellow-mark';
        } else if (task.priority === 2) {
            cls += ' green-mark';
        } else if (task.priority === 3) {
            cls += ' red-mark';
        }
    }

    return cls;
}

export function permissionToEditAbsenceList(currentUser, location, isAppointmentInfo, status) {
    if (!currentUser || !currentUser.roleIdentifier) {
        return false;
    }
    if (currentUser.permissions.includes('an_abwesenheit_von_allen_bearbeiten')) {
        return true;
    }
    if (isAppointmentInfo && currentUser.permissions.includes('an_abwesenheit_info_von_allen_bearbeiten')) {
        return true;
    }
    if (isAppointmentInfo && !currentUser.permissions.includes('an_abwesenheit_info_von_allen_bearbeiten')) {
        return false;
    }
    if (status === 2 && currentUser.permissions.includes('an_abwesenheit_krankmeldung_bearbeiten')) {
        return true;
    }
    if (status === 3 && currentUser.permissions.includes('an_abwesenheit_urlaub_bearbeiten')) {
        return true;
    }
    if (currentUser.permissions.includes('an_abwesenheit_in_eigener_filiale_bearbeiten')) {
        return location === currentUser.currentLocation.id
    }
    return false;
}

export function crop(value, maxLen) {
    if (!value) {
        return ''
    }
    if (value.length <= maxLen) {
        return value;
    }
    return value.substring(0, maxLen) + "...";
}



/**
 * @param userdata
 * @returns {*|Array}
 */
export function getUserData(userdata) {
    return userdata.split(",");
}

/**

 * @returns string
 */
export function now() {
    return moment().format('YYYY-MM-DD');
}

/*
export function checkInput(input, alert, alertFunction) {
    if (input) {
        if(input.length >= 1) {
            return true;
        } else {
            alertFunction
            return false;
        }
    } else {
        alert(alertMessage);
        return false;
    }
}
*/
