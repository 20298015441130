import DateInput from 'Components/UI/Form/DateInput';
import { UserSearchBox } from 'Containers/UserSearchBox';
import * as moment from 'moment';
import * as PropTypes from 'prop-types';
import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import Breadcrumb from '../Components/UI/Breadcrumb';
import Button from '../Components/UI/Button';
import { actions } from '../Redux/index';
import Dropdown from './Dropdown';
import FilterDropdown from './FilterDropdown';
import { getTaskPriorityClass} from './Util';
import {host} from "../Sagas/host";
import IconLink from "../Components/UI/IconLink";

const mapStateToProps = (state) => {
    return {
        locationDropdown: state.data.dropdown.news.location,
        teamDropdown: state.data.dropdown.news.team,
        userDropdown: state.data.dropdown.task.user,
        allLocations: state.data.allLocations,
        teams: state.data.teams,
        allUsers: state.data.allUsers,
        allUsersForTraining: state.training.allUsersForTraining,
        taskToEdit: state.task.taskToEdit,
        taskList: state.task.list,
        taskListFinished: state.task.listFinished,
        taskModal: state.data.modal.task.task,
        currentUser: state.data.currentUser,
        taskListNotFinishedCount: state.task.listNotFinishedCount,
        taskListFinishedCount: state.task.listFinishedCount,
        taskListFinishedView: state.task.taskListFinishedView,
        locationFilter: state.data.filter.task.location,
        teamFilter: state.data.filter.task.team,
        userSearch: state.ui.userSearch,
        taskView: state.ui.taskView,
        token: state.ui.token
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateView: (value) => dispatch(actions.Ui.updateView(value)),
        loadTaskList: (list) => dispatch(actions.Task.loadTaskList(list)),
        updateDropdown: (dropdown) => dispatch(actions.Data.updateDropdown(dropdown)),
        updateModal: (modal) => dispatch(actions.Data.updateModal(modal)),
        updateTaskToEdit: (task) => dispatch(actions.Task.updateTaskToEdit(task)),
        addTask: (task) => dispatch(actions.Task.addTask(task)),
        deleteTask: (task) => dispatch(actions.Task.deleteTask(task)),
        updateTask: (task) => dispatch(actions.Task.updateTask(task)),
        updateTaskListNotFinishedCount: (value) => dispatch(actions.Task.updateTaskListNotFinishedCount(value)),
        updateTaskListFinishedCount: (value) => dispatch(actions.Task.updateTaskListFinishedCount(value)),
        updateTaskListFinishedView: (value) => dispatch(actions.Task.updateTaskListFinishedView(value)),
        addTaskFinished: (task) => dispatch(actions.Task.addTaskFinished(task)),
        updateFilter: (filter) => dispatch(actions.Data.updateFilter(filter)),
        reloadTaskList: (list) => dispatch(actions.Task.reloadTaskList(list)),
        updateAbsenceList: (list) => dispatch(actions.Absence.updateAbsenceList(list)),
        updateAbsenceView: (value) => dispatch(actions.Absence.updateView(value)),
        loadAbsenceList: (list) => dispatch(actions.Absence.loadAbsenceList(list)),
        updateVacationMenuClass: (value) => dispatch(actions.Ui.updateVacationMenuClass(value)),
        updateUserSearch: (search) => dispatch(actions.Ui.updateUserSearch(search)),
        loadAllUsersForTraining: list => dispatch(actions.Training.loadAllUsersForTraining(list)),
        loadAllUsers: (value) => dispatch(actions.Data.loadAllUsers(value)),
        updateReference: (value) => dispatch(actions.Training.updateReference(value)),
        updateOldListView: (value) => dispatch(actions.Training.updateOldListView(value)),
        updateValuationReference: (value) => dispatch(actions.Training.updateValuationReference(value)),
    };
};

class Task extends PureComponent {
    static propTypes = {
        updateView: PropTypes.func.isRequired,
        taskList: PropTypes.array,
        loadTaskList: PropTypes.func,
        teams: PropTypes.array,
        currentUser: PropTypes.object,
        locationList: PropTypes.array,
        updateTaskToEdit:PropTypes.func,
        updateTask:PropTypes.func,
        addTaskFinished:PropTypes.func,
        updateFilter:PropTypes.func,
        updateTaskListNotFinishedCount:PropTypes.func,
        updateTaskListNotFinishedView:PropTypes.func,
        updateTaskListFinishedCount:PropTypes.func,
        taskListNotFinishedCount: PropTypes.number,
        taskListFinishedCount: PropTypes.number,
        reloadTaskList: PropTypes.func,
        updateUserSearch: PropTypes.func,
        updateReference: PropTypes.func,
        userSearch: PropTypes.string
    };

    addTask() {
        if(this.props.taskToEdit.title === '' || this.props.taskToEdit.title === undefined) {
            alert('Der Titel darf nicht leer sein.')
            return;
        }
        if(this.props.taskToEdit.date === '' || this.props.taskToEdit.date === undefined) {
            alert('Das Datum darf nicht leer sein.')
            return;
        }
        this.props.addTask({
            'id' : 'newsTask' + Math.floor((Math.random() * 100) + 1),
            'title': this.props.taskToEdit.title,
            'multiTask': this.props.taskToEdit.multiTask || false,
            'deadline': moment(this.props.taskToEdit.date).format('DD.MM.YYYY'),
            'deadlineFormated': moment(this.props.taskToEdit.date).format('DD.MM.YYYY'),
            'teams': this.props.teamDropdown.selectedId || [],
            'locations': this.props.locationDropdown.selectedId || [],
            'users': this.props.userDropdown.selectedId || [],
            'completed': false,
            'checked': false
        });
    }

    componentWillMount() {
        this.props.updateUserSearch('')
        if (this.can('aufgaben_an_alle_stellen')) {
            this.props.loadAllUsersForTraining(true);
        } else {
            this.props.loadAllUsers(true);
        }

    }

    getUsersDependingOnRole() {
        if (this.can('aufgaben_an_alle_stellen')) {
            return this.props.allUsersForTraining
        } else {
            return this.props.allUsers
        }
    }
    can (...args) {
        return args.some( permission => this.props.currentUser.permissions.includes(permission))
    }


    render() {
        return (
            <div id="main-side">
                <Breadcrumb path="Aufgaben">

                    {this.can('aufgaben_alle_sehen') && this.props.taskView === "2" &&
                        <>
                            <FilterDropdown
                                filter={this.props.locationFilter}
                                list={this.props.allLocations}
                                itemIdProp={'id'}
                                itemLabelProp={'label'}
                                defaultSelectedLabel={'Nach Ort Filtern'}
                                actionToExecute={this.props.reloadTaskList}
                            />
                            <FilterDropdown
                                filter={this.props.teamFilter}
                                list={this.props.teams}
                                itemIdProp={'name'}
                                itemLabelProp={'label'}
                                defaultSelectedLabel={'Nach Team Filtern'}
                                actionToExecute={this.props.reloadTaskList}
                            />
                        </>
                    }


                    {this.can('aufgaben_an_alle_stellen', 'aufgaben_an_mitarbeiter_aus_eigenen_filiale_stellen')&&
                        <Button
                        label="Neue Aufgaben"
                        onClick={() => {
                            this.props.updateUserSearch('')
                            this.props.updateModal({
                                path: 'task.task',
                                prop: 'view',
                                val: !this.props.taskModal.view,
                            });
                            if(this.can('aufgaben_an_mitarbeiter_aus_eigenen_filiale_stellen')) {
                                this.props.updateDropdown({dropdown: 'dropdown.news.location', prop:'selectedLabel', val:this.props.currentUser.currentLocation.label});
                                this.props.updateDropdown({dropdown: 'dropdown.news.location', prop:'selectedId', val:[this.props.currentUser.currentLocation.id]});
                            }

                        }}
                    />
                    }
                </Breadcrumb>

                <main>
                    <div id="taskModal" className={this.props.taskModal.view ? 'modal visible': 'hidden'}>
                        <div className="modal-content width-1000">
                            <div className="headline-counter">
                                <span>
                                    <i className="fa fa-newspaper-o" aria-hidden="true"/> Aufgabe {this.props.taskModal.action === 'newTask' ? 'erstellen' : 'speichern'}
                                </span> <span className="close">
                                    <a href="/#-" onClick={(e) => {
                                        e.preventDefault();
                                        this.props.updateModal({
                                            path: 'task.task',
                                            prop: 'view',
                                            val: !this.props.taskModal.view,
                                        });
                                    }}
                                    >
                                        <i className="fa fa-times-circle-o"/>
                                    </a>
                                </span>
                        </div>
                        <form>
                            <div className="content-wrapper content-form">
                                <div className="content-wrapper-box">
                                    <div className="grid-wrapper">
                                        <div className="small-12 medium-12 large-12">
                                            <div className="group">
                                                <input type="text" value={this.props.taskToEdit.title || ''} onChange={(e) => this.props.updateTaskToEdit({prop: 'title', val: e.target.value})} />
                                                <label>Titel</label>
                                            </div>
                                        </div>
                                        <div className="small-12 medium-6 large-6">
                                            <div className="group">
                                                <DateInput
                                                    selected={this.props.taskToEdit.date
                                                        ? new Date(this.props.taskToEdit.date)
                                                        : null}
                                                    onChange={(date) => this.props.updateTaskToEdit({
                                                        prop: 'date',
                                                        val: moment(date.toLocaleString('de-DE'), 'DD.MM.YYYY').format('YYYY-MM-DD'),
                                                    })}
                                                />

                                                <label>Erledigen bis</label>
                                            </div>

                                        </div>
                                        <div className="small-12 medium-6 large-6"></div>

                                            <div className="small-12 medium-6 large-6">
                                                <Dropdown dropdown={this.props.locationDropdown}
                                                          disabled={this.can('aufgaben_an_mitarbeiter_aus_eigenen_filiale_stellen')}
                                                          dropdownList={this.props.allLocations} itemIdProp={'id'} itemLabelProp={'label'} defaultSelectedLabel={'Alle'}  multiple={true}
                                                />

                                            </div>

                                            <div className="small-12 medium-6 large-6">
                                                <Dropdown dropdown={this.props.teamDropdown} dropdownList={this.props.teams} itemIdProp={'name'} itemLabelProp={'label'} defaultSelectedLabel={'Alle'} multiple={true}/>
                                            </div>

                                            <UserSearchBox userSearch={this.props.userSearch} updateUserSearch={this.props.updateUserSearch} updateDropdown={this.props.updateDropdown} userDropdown={this.props.userDropdown}/>

                                            <div className="small-12 medium-6 large-6">
                                                <Dropdown
                                                    dropdown={this.props.userDropdown}
                                                    dropdownList={this.getUsersDependingOnRole()}
                                                    itemIdProp={'id'}
                                                    itemLabelProp={'dropdownLabel'}
                                                    filterBy={this.props.userSearch}
                                                    filterProp={'searchName'}
                                                    defaultSelectedLabel={'Alle'}
                                                    multiple={true}/>
                                            </div>

                                            <div className="small-12 medium-12 large-12">
                                                <div className="check-list" style={{boxShadow: 'none'}}>
                                                        <div className="check-list-main">
                                                        <div className="checkbox-wrapper" style={{backgroundColor: '#fff'}}>
                                                            <label>
                                                                <span className={this.props.taskToEdit.multiTask === true ? 'boxChecked' : 'boxUnchecked'} onClick={() => this.props.updateTaskToEdit({prop: 'multiTask', val: !this.props.taskToEdit.multiTask})} />
                                                            </label>
                                                            <div className="checkbox-text">
                                                                <span className={'prim-text'}>Benutzer erhalten eine gemeinsame Aufgabe</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="content-wrapper">
                                    <div className="content-wrapper-box">
                                        <div className="grid-wrapper">
                                            <div className="small-12 medium-12 large-12">
                                                <Button
                                                    label={this.props.taskModal.action === 'newTask' ? 'Erstellen' : 'Speichern'}
                                                    inverted
                                                    onClick={() => this.addTask()}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="content-wrapper inner-ver">
                        <div className="content-wrapper-box">
                            <div className="grid-wrapper">
                                <div className="small-12 medium-12 large-12">
                                    <div className="check-list">
                                        <div className="check-list-headline">
                                                            <span><i className="fa fa-check-square" aria-hidden="true"/> {this.props.taskView === '2' ? 'Eigene' : 'Delegierte'} Aufgaben</span>
                                            <span className="counter">{this.props.taskListNotFinishedCount}</span>
                                        </div>
                                        <div className="check-list-main">
                                            {
                                                !this.props.taskList[0] &&
                                                <div className="checkbox-wrapper">
                                                    <div className="checkbox-text">
                                                        <span className="prim-text">Keine neuen Aufgaben</span>
                                                        <span className="sec-text"/>
                                                    </div>
                                                </div>
                                            }
                                        </div>

                                        {
                                            this.props.taskList.length !== 0 &&
                                            <div className="check-list-main">
                                                {
                                                    this.props.taskList.filter(task => (task.completed === false)).map((task, index) => {
                                                        if (task.id === 'defaultId') {
                                                            return (
                                                                <div className="checkbox-wrapper" key={index}>
                                                                    <a href="/#-" className="dokument-item" key={index}>
                                                                        <div className="default-list-item">
                                                                            <span></span>
                                                                        </div>
                                                                    </a>
                                                                </div>
                                                            )
                                                        }
                                                        else {
                                                            return (

                                                                <div className={getTaskPriorityClass(task)} key={index}>
                                                                    <label>
                                                                        {
                                                                            !task.locked &&
                                                                            <span className={task.checked === true ? 'boxChecked' : 'boxUnchecked'} onClick={() => {
                                                                                this.props.updateTask({id: task.id, prop: "checked", val: !task.checked});
                                                                                setTimeout(() => {
                                                                                    if (task.checked) {
                                                                                        this.props.updateTask({id: task.id, prop: "completed", val: true});
                                                                                        this.props.addTaskFinished(task);
                                                                                        this.props.updateTaskListNotFinishedCount(this.props.taskListNotFinishedCount - 1);
                                                                                    }
                                                                                }, 2000);
                                                                            }} />
                                                                        }
                                                                        {
                                                                            task.locked &&
                                                                            <span className={'boxUnchecked locked'} />
                                                                        }

                                                                    </label>
                                                                    <div className="checkbox-text">
                                                                        <span className={'prim-text'} style={{'cursor': task.link ? 'pointer': 'unset'}}
                                                                              onClick={(e) => {
                                                                                  e.preventDefault();
                                                                                  if (task.link === 'absenceView 1') {

                                                                                      this.props.updateVacationMenuClass('has-subnav open-submenu active');

                                                                                      this.props.updateView('Absence');
                                                                                      this.props.updateAbsenceView('1');
                                                                                      this.props.updateAbsenceList({ list: [], reset: true });
                                                                                      this.props.loadAbsenceList({
                                                                                          from: 0,
                                                                                          view: 1,
                                                                                          lazy: false,
                                                                                      });
                                                                                  }else if (task.link === 'absenceView 2') {

                                                                                    this.props.updateVacationMenuClass('has-subnav open-submenu active');

                                                                                    this.props.updateView('Absence');
                                                                                    this.props.updateAbsenceView('2');
                                                                                    this.props.updateAbsenceList({ list: [], reset: true });
                                                                                    this.props.loadAbsenceList({
                                                                                        from: 0,
                                                                                        view: 2,
                                                                                        lazy: false,
                                                                                    });
                                                                                } else if (task.link === 'Training'){
                                                                                    this.props.updateView('Training');
                                                                                    this.props.updateReference(task.reference);

                                                                                } else if (task.link === 'Valuation'){
                                                                                    this.props.updateView('Training');
                                                                                    this.props.updateReference(task.reference);
                                                                                    this.props.updateOldListView(true)
                                                                                    this.props.updateValuationReference(true)
                                                                                  }
                                                                              }}
                                                                            >{task.title}  {task.createdByFullName ? ` (${task.createdByFullName} | ${task.createdAtFormated})` : ''}</span>
                                                                        <span className={'sec-text'}>{task.deadlineFormated}</span>
                                                                        {
                                                                            task.subtasks &&
                                                                            <span className={'sec-text2'}>
                                                                                {task.subtasks.map((subtask, index) => (
                                                                                    <Fragment key={index}>
                                                                                        {subtask.completed && <i className="fa fa-check" style={{color: 'green'}}/>}
                                                                                        {!subtask.completed && <i className="fa fa-times" style={{color: 'red'}}/>}
                                                                                        <b key={index}>&nbsp;{subtask.name}</b>
                                                                                        <br/>
                                                                                    </Fragment>
                                                                                ))}
                                                                            </span>
                                                                        }
                                                                        {
                                                                            !('subtasks' in task) && this.props.taskView !== '2' &&
                                                                            <span className={'sec-text'}>
                                                                                loading ...
                                                                            </span>
                                                                        }

                                                                    </div>
                                                                </div>
                                                            )
                                                        }
                                                    })}
                                            </div>
                                        }
                                        <div className="grid-wrapper">
                                            <div className="small-12 medium-12 large-12">
                                                <div>
                                                    <br/>
                                                    <form action={host+'/export-tasks?finished=0&view='+this.props.taskView+'&location='+this.props.locationFilter.selectedId + '&team='+this.props.teamFilter.selectedId}
                                                          method="post"
                                                          ref={(ref) => {
                                                              this.downloadFormTasks = ref;
                                                          }}>
                                                        <input type="hidden" name="authTok"
                                                               value={'Bearer ' + this.props.token}
                                                        />
                                                    </form>
                                                    <IconLink type="download"  onClick={() => this.downloadFormTasks.submit()}> Export XLSX</IconLink>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {this.props.taskListNotFinishedCount > this.props.taskList.length &&
                    <div className="content-wrapper">
                        <div className="content-wrapper-box">
                            <div className="grid-wrapper">
                                <div className="small-12 medium-12 large-12">
                                    <Button
                                        label="Weitere Aufgaben anzeigen"
                                        inverted
                                        onClick={() => {
                                            this.props.loadTaskList({
                                                from: this.props.taskList.length,
                                                completed: false,
                                            });
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>}

                    <div className={this.props.taskListFinished.length ? "content-wrapper-box" : "hidden"}>
                        <div className="grid-wrapper">
                            <div className="small-12 medium-12 large-12">
                                <Button
                                    label={this.props.taskListFinishedView ? 'Erledigte Aufgaben ausblenden' : 'Erledigte Aufgaben anzeigen'}
                                    inverted
                                    onClick={() => this.props.updateTaskListFinishedView(!this.props.taskListFinishedView)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className={this.props.taskListFinishedView ? "content-wrapper banner overlay-box visible": "content-wrapper banner overlay-box hidden"}>
                        <div className="content-wrapper-box">
                            <div className="grid-wrapper">
                                <div className="small-12 medium-12 large-12">
                                    <div className="check-list-gray">
                                        <div className="check-list-main">
                                            { this.props.taskListFinished.map((task, index) => {
                                                if (task.id === 'defaultId') {
                                                    return (
                                                        <div className="checkbox-wrapper" key={index}>
                                                            <a href="/#-" className="dokument-item" key={index}>
                                                                <div className="default-list-item">
                                                                    <span></span>
                                                                </div>
                                                            </a>
                                                        </div>
                                                    )
                                                } else {
                                                    return (
                                                        <div className="checkbox-wrapper" key={index}>
                                                            <i className="fa fa-check-square" aria-hidden="true"></i>
                                                            <div className="checkbox-text">
                                                                <span className={'prim-text'}>{task.title}</span>
                                                                <span className={'sec-text'}>{task.deadlineFormated}</span>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            })}
                                        </div>
                                    </div>
                                    <div className="grid-wrapper">
                                        <div className="small-12 medium-12 large-12">
                                            <div>
                                                <br/>
                                                <form action={host+'/export-tasks?finished=1&view='+this.props.taskView+'&location='+this.props.locationFilter.selectedId + '&team='+this.props.teamFilter.selectedId}
                                                      method="post"
                                                      ref={(ref) => {
                                                          this.downloadFormTasksFinished = ref;
                                                      }}>
                                                    <input type="hidden" name="authTok"
                                                           value={'Bearer ' + this.props.token}
                                                    />
                                                </form>
                                                <IconLink type="download"  onClick={() => this.downloadFormTasksFinished.submit()}> Export XLSX</IconLink>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        this.props.taskListFinishedCount > this.props.taskListFinished.length &&
                        <div
                            className={this.props.taskListFinishedView ? "content-wrapper visible" : "content-wrapper hidden"}>
                            <div className="content-wrapper-box">
                                <div className="grid-wrapper">
                                    <div className="small-12 medium-12 large-12">
                                        <Button
                                            label="Weitere erledigte Aufgaben anzeigen"
                                            inverted
                                            onClick={() => this.props.loadTaskList({
                                                from: this.props.taskListFinished.length,
                                                completed: true,
                                            })}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </main>

                <footer id="footer-main">
                </footer>
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Task);
