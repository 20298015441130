import * as PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import Breadcrumb from '../Components/UI/Breadcrumb'
import Button from '../Components/UI/Button'
import { actions } from '../Redux/index'
import Dropdown from './Dropdown'

const mapStateToProps = (state) => {
    return {
        currentUser: state.data.currentUser,
        currentFolder: state.data.currentFolder,
        userToEdit: state.data.userToEdit,
        availableLocationsDropdown: state.data.dropdown.userData.availableLocations
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateView: (value) => dispatch(actions.Ui.updateView(value)),
        updateUserToEdit: (user) => dispatch(actions.Data.updateUserToEdit(user)),
        updateUserToEditData: (userdata) => dispatch(actions.Data.updateUserToEditData(userdata)),
        saveUser: (user) => dispatch(actions.Data.saveUser(user)),
        updateUser: (user) => dispatch(actions.Data.updateUser(user)),
        updateDropdown: dropdown => dispatch(actions.Data.updateDropdown(dropdown))
    };
};

class UserData extends PureComponent {
    static propTypes = {
        currentUser: PropTypes.object,
        updateView: PropTypes.func.isRequired,
        updateDropdown: PropTypes.func,
        availableLocationsDropdown: PropTypes.object
    };
    triggerFileInput(fileInput) {
        fileInput.click()
    }

    getUrl() {
        if (this.props.userToEdit.file) {
            return this.props.userToEdit.file;
        } else if (this.props.currentUser.resourceUri) {
            return this.props.currentUser.resourceUri;
        } else {
            return ''
        }
    }

    getBase64(file) {
        if (!file) {
            return null;
        }
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            console.log(file.name);
            this.props.updateUserToEditData({prop:'file', val: reader.result});
            this.props.updateUserToEditData({prop:'fileName', val: file.name.split(".")[0]})
        };
    }


    saveUser() {
        if (!this.checkInput(this.props.userToEdit.firstName)) {
            return;
        }
        if (!this.checkInput(this.props.userToEdit.lastName)) {
            return;
        }
        if (!this.checkPassword(this.props.userToEdit.password, this.props.userToEdit.passwordRepeat)) {
            return;
        }
        if (this.props.userToEdit.file) {
            this.props.updateUser(
                { 'user': {
                    'firstName': this.props.userToEdit.firstName,
                    'lastName': this.props.userToEdit.lastName,
                    'phone': this.props.userToEdit.phone || '',
                    'selectedLocation': this.props.availableLocationsDropdown.selectedId || '',
                },
                    'image': this.props.userToEdit.file,
                    'password': this.props.userToEdit.password || '',
                    'updateCalendar': false
                });
        } else {
            this.props.updateUser(
                { 'user': {
                    'firstName': this.props.userToEdit.firstName,
                    'lastName': this.props.userToEdit.lastName,
                    'phone': this.props.userToEdit.phone || '',
                    'selectedLocation': this.props.availableLocationsDropdown.selectedId || '',
                },
                    'password': this.props.userToEdit.password || '',
                    'updateCalendar': false
                });
        }
        this.props.updateView('AllTasks');
    }

    checkInput(input) {
        if (input) {
            if(input.length > 1) {
                return true;
            } else {
                alert('Bitte füllen Sie alle Felder aus.');
                return false;
            }
        } else {
            alert('Bitte füllen Sie alle Felder aus.');
            return false;
        }
    }

    checkPassword(password1, password2) {
        if (!password1) {
            return true;
        }
        if (password1 !== password2) {
            alert('Passwörter sind nicht identisch.')
            return false;
        }
        if (password1.length < 6) {
            alert('Passwort muss mindestens 6 zeichen lang sein.')
            return false;
        }
        return true;
    }

    render() {
        return (
            <div id="main-side">
                <Breadcrumb path="Benutzereinstellungen"/>

                <main>
                    <div id="Modal" className="modal">
                        <div className="modal-content width-1000">
                            <div className="headline-counter">
                                <span><i className="fa fa-user" aria-hidden="true"></i> Benutzereinstellungen
                                </span> <span className="close">
                                    <a href="/#-"
                                       onClick={(e) => {
                                           e.preventDefault();
                                           this.props.updateView('AllTasks');
                                       }}
                                    >
                                    <i className="fa fa-times-circle-o"></i>
                                    </a>
                                </span>
                            </div>
                            <form>
                                <div className="content-wrapper content-form">
                                    <div className="content-wrapper-box">
                                        <div className="grid-wrapper">
                                            <div className="small-12 medium-6 large-6">
                                                <div className="grid-wrapper">
                                                    <div className="small-12 medium-12 large-12">
                                                        <div className="group">
                                                            <input type="text" value={this.props.userToEdit.lastName || this.props.currentUser.lastName || ''} onChange={(e) => this.props.updateUserToEditData({
                                                                'prop': 'lastName',
                                                                'val': e.target.value,
                                                            })}/> <label>Name</label>
                                                        </div>
                                                    </div>
                                                    <div className="small-12 medium-12 large-12">
                                                        <div className="group">
                                                            <input type="text" value={this.props.userToEdit.firstName || this.props.currentUser.firstName || ''} onChange={(e) => this.props.updateUserToEditData({
                                                                'prop': 'firstName',
                                                                'val': e.target.value,
                                                            })}/> <label>Vorname</label>
                                                            <label>Vorname</label>
                                                        </div>
                                                    </div>
                                                    <div className="small-12 medium-12 large-12">
                                                        <div className="group">
                                                            <input type="text" disabled = "disabled" value={this.props.currentUser.email || ''} />
                                                            <label>E-Mail</label>
                                                        </div>
                                                    </div>
                                                    <div className="small-12 medium-12 large-12">
                                                        <div className="group">
                                                            <input type="text" value={this.props.userToEdit.phone || this.props.currentUser.phone || ''} onChange={(e) => this.props.updateUserToEditData({
                                                                'prop': 'phone',
                                                                'val': e.target.value,
                                                            })}/>
                                                            <label>Telefon</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="small-12 medium-6 large-6">
                                                <a href="/"
                                                   onClick={(e) => { e.preventDefault(); this.triggerFileInput(this.refs.fileInput)}}
                                                   className="formimagelink">
                                                    <div className="formimage">
                                                        <div className="content"
                                                            style={{
                                                                backgroundImage: "url("+this.getUrl()+")",
                                                                backgroundPosition: "center",
                                                                borderRadius: "50%",
                                                                backgroundRepeat: "no-repeat",
                                                                backgroundSize: "cover",
                                                            }}
                                                        >
                                                            <p>
                                                                Bild hochladen
                                                            </p>
                                                        </div>
                                                    </div>
                                                </a>
                                                <input type="file" ref="fileInput" onChange={(e) => {e.preventDefault(); this.getBase64(e.target.files[0]);}} />
                                            </div>
                                            <div className="small-12 medium-12 large-12">
                                                <div className="grid-wrapper">
                                                    <div className="small-12 medium-6 large-6">
                                                        <div className="group">
                                                            {this.props.currentUser.availableLocations &&
                                                                <Dropdown dropdown={this.props.availableLocationsDropdown} dropdownList={this.props.currentUser.availableLocations} itemIdProp={'id'} itemLabelProp={'label'} defaultSelectedLabel={'Bitte wählen'}/>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="small-12 medium-12 large-12">
                                                <div className="grid-wrapper">
                                                    <div className="small-12 medium-6 large-6">
                                                        <div className="group">
                                                            <input type="password" value={this.props.userToEdit.password || ''} onChange={(e) => this.props.updateUserToEditData({
                                                                'prop': 'password',
                                                                'val': e.target.value,
                                                            })}/> <label>Neues Passwort</label>
                                                        </div>
                                                    </div>
                                                    <div className="small-12 medium-6 large-6">
                                                        <div className="group">
                                                            <input type="password" value={this.props.userToEdit.passwordRepeat || ''} onChange={(e) => this.props.updateUserToEditData({
                                                                'prop': 'passwordRepeat',
                                                                'val': e.target.value,
                                                            })}/> <label>Passwortwiederholung</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                                <div className="content-wrapper">
                                    <div className="content-wrapper-box">
                                        <div className="grid-wrapper">
                                            <div className="small-12 medium-12 large-12">
                                                <Button label="Speichern"
                                                        onClick={() => this.saveUser()}
                                                        inverted/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </main>

                <footer id="footer-main">
                </footer>
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserData);
