import DateInput from 'Components/UI/Form/DateInput';
import { UserSearchBox } from 'Containers/UserSearchBox';
import * as PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import Breadcrumb from '../Components/UI/Breadcrumb';
import Button from '../Components/UI/Button';
import { actions } from '../Redux/index';
import FilterDropdown from './FilterDropdown';
import Dropdown from './Dropdown';
import * as moment from 'moment';
import IconLink from '../Components/UI/IconLink';
import { host } from '../Sagas/host';

const mapStateToProps = (state) => {
    return {
        currentUser: state.data.currentUser,
        locationFilter: state.data.filter.sickReport.location,
        allLocations: state.data.allLocations,
        sickReportView: state.sickReport.view,
        list: state.sickReport.list,
        listCount: state.sickReport.listCount,
        loadingAnimation: state.sickReport.loadingAnimation,
        sickReportModal: state.data.modal.sickReport.sickReport,
        sickReport: state.sickReport.sickReport,
        userDropdown: state.data.dropdown.sickReport.user,
        allUsers: state.data.allUsers,
        userSearch: state.ui.userSearch,
        selectedLocation: state.data.filter.sickReport.location,
        token: state.ui.token,
        yearFilter: state.data.yearFilter,
        absenceSorting: state.absence.sorting,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateView: (value) => dispatch(actions.Ui.updateView(value)),
        updateFilter: (filter) => dispatch(actions.Data.updateFilter(filter)),
        updateSickReport: (sickReport) => dispatch(actions.SickReport.updateSickReport(sickReport)),
        loadSickReportList: (list) => dispatch(actions.SickReport.loadSickReportList(list)),
        updateSickReportList: (list) => dispatch(actions.SickReport.updateSickReportList(list)),
        deleteSickReport: (sickReport) => dispatch(actions.SickReport.deleteSickReport(sickReport)),
        saveSickReport: (sickReport) => dispatch(actions.SickReport.saveSickReport(sickReport)),
        updateModal: (modal) => dispatch(actions.Data.updateModal(modal)),
        updateDropdown: (dropdown) => dispatch(actions.Data.updateDropdown(dropdown)),
        updateUserSearch: (search) => dispatch(actions.Ui.updateUserSearch(search)),
        updateConfirmModal: (modal) => dispatch(actions.Ui.updateConfirmModal(modal)),
        loadAllUsers: (value) => dispatch(actions.Data.loadAllUsers(value)),
        updateYearFilter: (yearFilter) => dispatch(actions.Data.updateYearFilter(yearFilter)),
        updateAbsenceSorting: (value) => dispatch(actions.Absence.updateAbsenceSorting(value)),
    };
};

class SickReport extends PureComponent {
    static propTypes = {
        updateView: PropTypes.func.isRequired,
        updateCalendar: PropTypes.func,
        calendar: PropTypes.array,
        allLocations: PropTypes.array,
        updateFilter: PropTypes.func.isRequired,
        absenceView: PropTypes.string,
        list: PropTypes.array,
        listCount: PropTypes.number,
        loadingList: PropTypes.bool,
        updateAbsenceApproved: PropTypes.func,
        deleteAbsence: PropTypes.func,

        updateSickReport: PropTypes.func.isRequired,
        sickReport: PropTypes.object,
        loadAllUsers: PropTypes.func,
        updateYearFilter: PropTypes.func,
        absenceSorting: PropTypes.array
    };
    componentWillMount() {
        this.props.updateUserSearch('');
        this.props.updateYearFilter('');
        this.props.loadAllUsers(true);
        this.props.updateSickReportList({list: [], reset: true});
        this.props.loadSickReportList({from: 0, view: this.props.sickReportView, lazy: false});
    }

    sickReportViewTitle() {
        const titles = {
            "2": "Eingegangene Krankmeldungen",
            "3": "Vergangene Krankmeldungen",
            "4": "Alle Krankmeldungen",
        };
        return titles[this.props.sickReportView];
    }
    sickReportViewIcon() {
        const icons = {
            "2": "fa fa-ambulance",
            "3": "fa fa-history",
            "4": "fa fa-bars"
        };
        return icons[this.props.sickReportView];
    }

    getLink()  {
        let location = '';
        if (this.props.selectedLocation.selectedId !== '') {
            location = this.props.selectedLocation.selectedId
        }else if (this.props.currentUser.location) {
            location = this.props.currentUser.currentLocation.id
        }
        const yearRegex = /(?:(?:18|19|20|21)[0-9]{2})/g;
        let yearFilter = '';
        if ((yearRegex.test(this.props.yearFilter))) {
            yearFilter = `?year=${this.props.yearFilter}`
        }
        return `${host}/exportsickreport/${location}/${this.props.sickReportView}${yearFilter}`
    }

    showSorting(column) {
        if(this.props.absenceSorting.includes(column)){
            return <i className="fa fa-angle-up" />
        }
        if(this.props.absenceSorting.includes(`-${column}`)){
            return <i className="fa fa-angle-down" />
        }

    }
    updateSorting(prop) {
        this.props.updateAbsenceSorting(prop)
        if (this.props.sickReportView !== '4') {
            this.props.updateSickReportList({list: [], reset: true});
            this.props.loadSickReportList({from: 0, view: this.props.sickReportView, lazy: false});
        }
    }

    /**
     * @param userdata
     * @returns {*|Array}
     */
    getUserData(userdata) {
            return userdata.split(",");
    }

    saveSickReport() {
        if(this.props.sickReport.startDate === '' || this.props.sickReport.startDate === undefined) {
            alert('Das Datum darf nicht leer sein.')
            return;
        }
        if(this.props.sickReport.endDate === '' || this.props.sickReport.endDate === undefined) {
            alert('Das Datum bis darf nicht leer sein.')
            return;
        }


        if (this.props.userDropdown.selectedId === "" && this.props.sickReportModal.action === "newSickReport") {
            alert('Das Datum bis darf nicht leer sein.')
            return;
        }
        if (this.props.sickReportModal.action === "newSickReport") {
            this.props.updateSickReport({ prop: 'userId', val: this.props.userDropdown.selectedId});
            this.props.saveSickReport('new');
        } else {
            this.props.saveSickReport('update');
        }




    }

    editSickReport(sickReport) {
        this.props.updateSickReport({ prop: 'id', val: sickReport.id });
        this.props.updateSickReport({ prop: 'userId', val: sickReport.userId });
        this.props.updateSickReport({ prop: 'startDate', val: moment(sickReport.startDateFormated, 'DD.MM.YYYY').format('YYYY-MM-DD') });
        this.props.updateSickReport({ prop: 'endDate', val: moment(sickReport.endDateFormated, 'DD.MM.YYYY').format('YYYY-MM-DD') });
        this.props.updateModal({
            path: 'sickReport.sickReport',
            prop: 'view',
            val: !this.props.sickReportModal.view,
        });
        this.props.updateModal({
            path: 'sickReport.sickReport',
            prop: 'action',
            val: 'editSickReport',
        });


    }

    deleteSickReport(sickReport) {
        this.props.updateConfirmModal({prop: 'visible', value: true})
        this.props.updateConfirmModal({prop: 'title', value: 'Krankmeldung löschen?'})
        this.props.updateConfirmModal({prop: 'text', value: 'Krankmeldung löschen?'})
        this.props.updateConfirmModal({prop: 'label', value: "Löschen"})
        this.props.updateConfirmModal({prop: 'payload', value: {actionType: 'deleteSickReport', param: sickReport}})
    }

    getSickReportUserName() {
        let currentUser = this.props.allUsers.find(
            (user) => user.id === this.props.sickReport.userId
        );
        return currentUser
            ? currentUser.firstName + ' ' + currentUser.lastName + ' - ' + currentUser.currentLocationLabel
            : '';
    }

    can (...args) {
        return args.some( permission => this.props.currentUser.permissions.includes(permission))
    }

    deletedUser() {
        return <span style={{opacity: 0.3}}>Benutzer gelöscht</span>
    }
    render() {
        const emptyList = [1,2,3,4,5,6,7,8,9];
        const emptyStyle = {minWidth: '100px'};
        let allLocations = this.props.allLocations
        if(this.can('krankmeldungen_nach_filiale_filtern')) {
            allLocations = [{label: 'Alle', id: 'receivealllocations'}, ...this.props.allLocations]
        }
        return (
            <div id="main-side">
                <Breadcrumb path={['Krankmeldung', this.sickReportViewTitle()]}>
                    {this.can('krankmeldungen_nach_filiale_filtern') &&
                    <div className="visible btn-3">
                            <FilterDropdown
                                filter={this.props.locationFilter}
                                list={allLocations}
                                itemIdProp={'id'}
                                itemLabelProp={'label'}
                                resetFilterLabel={true}
                                defaultSelectedLabel={'Ort wechseln'}
                                actionToExecute={this.props.loadSickReportList}
                                paramsToExecute={{ from: 0, view: this.props.sickReportView }}
                                secondActionToExecute={this.props.updateSickReportList}
                                secondParamsToExecute={{ list: [], reset: true }}
                            />
                    </div>}
                    {this.can('krankmeldungen_nach_mitarbeiter_filtern') &&
                    <div className="btn-3 absence-date">
                        <input type="text" name='test'
                            value={this.props.userSearch || ''}
                            placeholder={'Suche Mitarbeiter'}
                            onChange={e => {
                                this.props.updateUserSearch(e.target.value)
                                this.props.loadSickReportList({
                                    'from': this.props.list.length,
                                    view: this.props.sickReportView,
                                    lazy: true,
                                })
                            }}
                        />
                    </div>}
                    {this.props.sickReportView !== '4' &&
                        <div className="btn-3 absence-date year-filter">
                            <input type="text"
                                   value={this.props.yearFilter || ''}
                                   placeholder={'Jahr'}
                                   onChange={e => {
                                       this.props.updateYearFilter(e.target.value);
                                       const yearRegex = /(?:(?:18|19|20|21)[0-9]{2})/g;
                                       if ((yearRegex.test(e.target.value)) || e.target.value === '') {
                                           this.props.updateSickReportList({list: [], reset: true});
                                           this.props.loadSickReportList({
                                               'from': 0,
                                               view: this.props.sickReportView,
                                               lazy: false,
                                           })
                                       }
                                   }}
                            />
                    </div>
                    }
                    {this.can('krankmeldungen_von_allen_sehen_und_bearbeiten',
                        'krankmeldungen_von_allen_aus_eigener_filiale_sehen_und_bearbeiten') &&
                    <Button label='Neue Krankmeldung' onClick={() => {
                        this.props.updateUserSearch('')
                        this.props.updateSickReport({ prop: 'userId', val: this.props.currentUser.id });
                        this.props.updateModal({
                            path: 'sickReport.sickReport',
                            prop: 'view',
                            val: !this.props.sickReportModal.view,
                        });
                        this.props.updateModal({
                            path: 'sickReport.sickReport',
                            prop: 'action',
                            val: 'newSickReport',
                        });
                    }}
                    />}
                </Breadcrumb>

                <main>
                    <div id="sickReportModal" className={this.props.sickReportModal.view ? 'modal visible': 'hidden'}>
                        <div className="modal-content width-1000">
                            <div className="headline-counter">
                                <span>
                                    <i className="fa fa-ambulance" aria-hidden="true"/> {this.props.sickReportModal.action === 'newSickReport' ? 'Neue Krankmeldung' : 'Krankmeldung bearbeiten'}
                                </span> <span className="close">
                                    <a href="/" onClick={(e) => { e.preventDefault(); this.props.updateSickReport({prop: 'resetObj', val: ''}); this.props.updateModal({path: 'sickReport.sickReport', prop: 'view', val: !this.props.sickReportModal.view}); }}>
                                        <i className="fa fa-times-circle-o"/>
                                    </a>
                                </span>
                            </div>
                            <form>
                                <div className="content-wrapper content-form">
                                    <div className="content-wrapper-box">
                                        <div className="grid-wrapper">
                                            { this.props.sickReportModal.action === 'newSickReport' &&
                                            <UserSearchBox userSearch={this.props.userSearch} updateUserSearch={this.props.updateUserSearch} updateDropdown={this.props.updateDropdown} userDropdown={this.props.userDropdown}/>
                                            }
                                            { this.props.sickReportModal.action === 'newSickReport' &&
                                                <div className="small-12 medium-6 large-6">
                                                    <Dropdown
                                                        dropdown={this.props.userDropdown}
                                                        dropdownList={this.props.allUsers}
                                                        itemIdProp={'id'}
                                                        itemLabelProp={'dropdownLabel'}
                                                        filterProp={'searchName'}
                                                        filterBy={this.props.userSearch}
                                                        defaultSelectedLabel={'Bitte wählen'} multiple={false}/>
                                                </div>
                                            }

                                            { this.props.sickReportModal.action === 'editSickReport' &&
                                            <div className="small-12 medium-12 large-12">
                                                <div className="group">
                                                    <input
                                                        type="text"
                                                        value={this.getSickReportUserName() || ""}
                                                        disabled={'disabled'}
                                                    />
                                                    <label>Name</label>
                                                </div>
                                            </div>


                                            }

                                            <div className="small-12 medium-6 large-6">
                                                <div className="group">
                                                    <DateInput
                                                        selected={this.props.sickReport.startDate
                                                            ? new Date(this.props.sickReport.startDate)
                                                            : null}
                                                        onChange={(date) => this.props.updateSickReport({
                                                            prop: 'startDate',
                                                            val: moment(date.toLocaleString('de-DE'), 'DD.MM.YYYY').format('YYYY-MM-DD'),
                                                        })}
                                                    />

                                                    <label>Datum</label>
                                                </div>
                                            </div>
                                            <div className="small-12 medium-6 large-6">
                                                <div className="group">
                                                    <DateInput
                                                        minDate={new Date(this.props.sickReport.startDate)}
                                                        selected={this.props.sickReport.endDate
                                                            ? new Date(this.props.sickReport.endDate)
                                                            : null}
                                                        onChange={(date) => this.props.updateSickReport({
                                                            prop: 'endDate',
                                                            val: moment(date.toLocaleString('de-DE'), 'DD.MM.YYYY').format('YYYY-MM-DD')
                                                        })}
                                                    />

                                                    <label>Datum bis</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="content-wrapper">
                                    <div className="content-wrapper-box">
                                        <div className="grid-wrapper">
                                            <div className="small-12 medium-12 large-12">
                                                <Button
                                                    label="Speichern"
                                                    inverted
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        this.saveSickReport();
                                                    }}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="content-wrapper margin-top">
                        <div className="content-wrapper-box">
                            <div className="grid-wrapper">
                                <div className="small-12 medium-12 large-12">
                                    <div className="site-wrapper">
                                        <div className="headline">
                                            <span><i className={this.sickReportViewIcon()} aria-hidden="true"></i> {this.sickReportViewTitle()} </span>
                                        </div>
                                        <div className="overflow-x">
                                            <table>
                                                <tbody>
                                                    {this.props.sickReportView !== "4" &&
                                                        <tr>
                                                            <th onClick={() => this.updateSorting('usr.lastName')}><span>Name {this.showSorting('usr.lastName')}</span></th>
                                                            <th onClick={() => this.updateSorting('usr.firstName')}><span>Vorname {this.showSorting('usr.firstName')}</span></th>
                                                            <th onClick={() => this.updateSorting('usr.location.name')}><span>Standort {this.showSorting('usr.location.name')}</span></th>
                                                            <th onClick={() => this.updateSorting('startDate')}><span>Zeitraum {this.showSorting('startDate')}</span></th>
                                                            <th onClick={() => this.updateSorting('confirmedBy')}><span>Bearbeitet von {this.showSorting('confirmedBy')}</span></th>
                                                            <th onClick={() => this.updateSorting('confirmedAt')}><span>Bearbeitet am {this.showSorting('confirmedAt')}</span></th>
                                                            <th><span>Aktionen</span></th>
                                                        </tr>
                                                    }
                                                    {this.props.sickReportView === "4" &&
                                                        <tr>
                                                            <th onClick={() => this.updateSorting('lastName')}><span>Name {this.showSorting('lastName')}</span></th>
                                                            <th onClick={() => this.updateSorting('firstName')}><span>Vorname {this.showSorting('firstName')}</span></th>
                                                            <th onClick={() => this.updateSorting('personalNumber')}><span>Personalnummer {this.showSorting('personalNumber')}</span></th>
                                                            <th onClick={() => this.updateSorting('locationLabel')}><span>Standort {this.showSorting('locationLabel')}</span></th>
                                                            <th onClick={() => this.updateSorting('sickDaysThisYear')}><span>Krankheitstage {moment().format('YYYY')} {this.showSorting('sickDaysThisYear')}</span></th>
                                                            <th onClick={() => this.updateSorting('sickDaysLastYear')}><span>Krankheitstage {moment().format('YYYY') - 1} {this.showSorting('sickDaysLastYear')}</span></th>
                                                        </tr>
                                                    }

                                                {this.props.loadingAnimation === true &&
                                                    emptyList.map((item, index) => (
                                                        <tr key={index}>
                                                            <td>
                                                                <div className="default-list-item">
                                                                    <span style={emptyStyle}></span>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="default-list-item">
                                                                    <span style={emptyStyle}></span>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="default-list-item">
                                                                    <span style={emptyStyle}></span>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="default-list-item">
                                                                    <span style={emptyStyle}></span>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="default-list-item">
                                                                    <span style={emptyStyle}></span>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="default-list-item">
                                                                    <span style={emptyStyle}></span>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="default-list-item">
                                                                    <span style={emptyStyle}></span>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ))
                                                }
                                                {this.props.loadingAnimation !== true && this.props.sickReportView !== "4" &&
                                                    this.props.list.filter((sickReport) => (sickReport.userData.toLowerCase().indexOf(this.props.userSearch.toLowerCase()) >= 0)).map((sickReport, index) => (
                                                        <tr key={sickReport.id}>
                                                            <td><span>{this.getUserData(sickReport.userData)[1] || this.deletedUser()}</span></td>
                                                            <td><span>{this.getUserData(sickReport.userData)[0] || this.deletedUser()}</span></td>
                                                            <td><span>{this.getUserData(sickReport.userData)[2] || this.deletedUser()}</span></td>
                                                            <td><span>{sickReport.startDateFormated} - {sickReport.endDateFormated}</span></td>
                                                            <td><span>{this.getUserData(sickReport.confirmedByData)[1]} {this.getUserData(sickReport.confirmedByData)[0]}</span></td>
                                                            <td><span>{sickReport.confirmedAtFormated}</span></td>
                                                            <td>
                                                                <span>


                                                                    { this.can('krankmeldungen_von_allen_sehen_und_bearbeiten',
                                                                    'krankmeldungen_von_allen_aus_eigener_filiale_sehen_und_bearbeiten') &&
                                                                        <i onClick={() => this.editSickReport(sickReport)}
                                                                           className="fa fa-pencil" aria-hidden="true"/>
                                                                    }


                                                                    { this.can('krankmeldungen_von_allen_l_schen', 'krankmeldungen_von_allen_aus_eigener_filiale_l_schen') &&
                                                                        <i onClick={() => this.deleteSickReport(sickReport) }
                                                                            className="fa fa-trash" aria-hidden="true"/>
                                                                    }
                                                                </span>
                                                            </td>
                                                        </tr>
                                                    ))
                                                }
                                                {this.props.loadingAnimation !== true && this.props.sickReportView === "4" &&
                                                    this.props.list
                                                        .sort((a, b) => {
                                                            let sortProp = this.props.absenceSorting[0] || 'teamSortIndex'
                                                            const sortPropWithoutSign = sortProp.replace('-', '')
                                                                .replace('usr.', '')
                                                                .replace('usr.location.name', 'locationLabel');
                                                            let propa = a[sortPropWithoutSign];
                                                            let propb = b[sortPropWithoutSign];
                                                            if(['sickDaysThisYear','sickDaysLastYear'].includes(sortPropWithoutSign)) {
                                                                propa = Number(propa);
                                                                propb = Number(propb);
                                                            }
                                                            if (propa > propb) {
                                                                return sortProp[0] === '-' ? -1 : 1;
                                                            } else if(propa < propb) {
                                                                return sortProp[0] === '-' ? 1 : -1;
                                                            }
                                                            return 0;
                                                        })
                                                        .filter((user) => (user.searchName.toLowerCase().indexOf(this.props.userSearch.toLowerCase()) >= 0)).map((user, index) => (
                                                        <tr key={user.id}>
                                                            <td><span>{user.lastName}</span></td>
                                                            <td><span>{user.firstName}</span></td>
                                                            <td><span>{user.personalNumber}</span></td>
                                                            <td><span>{user.locationLabel}</span></td>
                                                            <td><span>{user.sickDaysThisYear}</span></td>
                                                            <td><span>{user.sickDaysLastYear}</span></td>
                                                        </tr>
                                                    ))
                                                }

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {this.props.listCount > this.props.list.length &&
                    <div className="content-wrapper inner-ver">
                        <div className="content-wrapper-box">
                            <div className="grid-wrapper">
                                <div className="small-12 medium-12 large-12">
                                    <Button
                                        icon="refresh"
                                        label="Weitere Einträge anzeigen"
                                        inverted
                                        onClick={() => this.props.loadSickReportList({
                                            'from': this.props.list.length,
                                            view: this.props.sickReportView,
                                            lazy: true,
                                        })}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>}
                    {this.can('krankmeldungen_exportieren') &&
                    <div className="content-wrapper inner-ver">
                        <div className="content-wrapper-box">
                            <div className="grid-wrapper">
                                <div className="small-12 medium-12 large-12">
                                    <div>
                                        <br/>
                                        <form action={this.getLink()}
                                                method="post"
                                                ref={ ref => {
                                                    this.downloadForm = ref;
                                                }}>
                                            <input type="hidden" name="authTok"
                                                    value={'Bearer ' + this.props.token}
                                            />
                                        </form>
                                        <IconLink type="download"  onClick={() => this.downloadForm.submit()}> Export XLSX</IconLink>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    }
                </main>

                <footer id="footer-main">
                </footer>
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SickReport)
