export default {
    username: '',
    password: '',
    token: '',
    showLoginError: false,
    searchOptionsWrapper: false,
    searchOptionNumber: false,
    searchOptionTitle: false,
    searchOptionCustomer: false,
    searchOptionEngineer: false,
    view: 'Dashboard',
    newTicketStep: 1,
    newPasswordError: false,
    saveDataButton: false,
    savePasswordButton: false,
    newTicketView: false,
    contactList: false,
    lazyButton: false,
    searchTerm: '',
    ticketInEditingMode: false,
    contactPersonList: false,
    showPasswordForgottenView: false,
    showPasswordSentMessage: false,
    newTaskView: false,
    userListPage: 0,
    newsListPage: 0,
    taskListPage: 0,
    completedTaskListPage: 0,
    locationListPage: 0,
    manufacturerListPage: 0,
    colorListPage: 0,
    loginLogListPage: 0,
    newLocationModal: false,
    newUserModal: false,
    newManufacturerModal: false,
    colorModal: false,
    locationCount: 0,
    userCount: 0,
    loginLogCount: 0,
    manufacturerCount: 0,
    colorCount: 0,
    newTaskUserGroupDropdown: false,
    newTaskUserLocationDropdown: false,
    newsListFurtherButton: true,
    taskListFurtherButton: true,
    completedTaskListFurtherButton: true,
    taskCheckbox: false,
    incompleteTaskCount: 0,
    completedTaskCount: 0,
    alert: {
        view: 'hidden',
        type: 'info',
        message: '',
    },
    vacationMenuClass: 'has-subnav',
    sickReportMenuClass: 'has-subnav',
    taskMenuClass: 'has-subnav',
    trainingMenuClass: 'has-subnav',
    currentHolidaysOnDashboard: {
      val: 0,
      label: 'Tage übrig'
    },
    stockLoadingAnimation: false,
    deliveryCentralWarehouse: false,
    deliveryCentralWarehouse2: false,
    loginLoaderAnimation: false,
    scrollPosition: 0,
    userSearch: '',
    documentSearch: '',
    taskView: '2',
    countNews: 0,
    countClaims: 0,
    countTrainings: 0,
    countAdvertisings: 0,
    headerOpen: false,
    absenceWeekFilter: '',
    stickyClass: 'sticky-header',
    confirmModal: {
      title: 'Löschen',
      text: 'Bist du dir sicher, dass du das löschen möchtest?',
      visible: false,
      payload: null,
    },
    locationSpecialAppointmentListPage: 0,
    locationSpecialAppointmentListCount: 0,
    newsModalAfterLogin: false,
    locationSpecialAppointmentViewOld: false,
    locationSpecialAppointmentExists: false,
    currentArticleBackgroundColor: {
      id: null,
      color: null
    },
    colorPalette: false,
    claimDropdownManufacturerListPage: 0,
    claimDropdownManufacturerModal: false,
    claimDropdownManufacturerCount: 0,
    claimDropdownReasonListPage: 0,
    claimDropdownReasonModal: false,
    claimDropdownReasonCount: 0,
    claimDropdownQualityListPage: 0,
    claimDropdownQualityModal: false,
    claimDropdownQualityCount: 0,
};
