import { createAction } from 'redux-actions';
import { handleActions } from '../../Utility/HandleActions';
import { $get, $set } from 'plow-js';
import initialState from './initialState';

const LOGIN = 'Ui/Login';
const UPDATE_USERNAME = 'Ui/UpdateUsername';
const UPDATE_PASSWORD = 'Ui/UpdatePassword';
const UPDATE_TOKEN = 'Ui/UpdateToken';
const SHOW_LOGIN_ERROR = 'Ui/ShowLoginError';
const UPDATE_VIEW = "Ui/UpdateView";
const UPDATE_NEW_TICKET_VIEW = 'Ui/UpdateNewTicketView';
const SHOW_CONTACT_LIST = "UI/ShowContactList";
const UPDATE_GRID_VIEW = "Ui/UpdateGridView";
const TOGGLE_DROPDOWN = "Ui/ToggleDropdown";
const TOGGLE_TAB = 'Ui/ToggleSingleArticleTab';
const UPDATE_LAZY_BUTTON = 'Ui/UpdateLazyButton';
const UPDATE_NEW_TASK_VIEW = 'Ui/UpdateNewTaskView';
const UPDATE_TASK_LIST_PAGE = 'Ui/UpdateTaskListPage';
const UPDATE_COMPLETED_TASK_LIST_PAGE = 'Ui/UpdateCompletedTaskListPage';
const UPDATE_NEWS_LIST_PAGE = 'Ui/UpdateNewsListPage';
const UPDATE_LOCATION_MODAL = 'Ui/UpdateLocationModal';
const UPDATE_MANUFACTURER_MODAL = 'Ui/UpdateManufacturerModal';
const UPDATE_LOCATION_COUNT = 'Ui/UpdateLocationCount';
const COUNT_LOCATION = 'Ui/CountLocation';
const COUNT_LOGIN_LOG = 'Ui/CountLoginLog';
const COUNT_MANUFACTURER = 'Ui/CountManufacturer';
const COUNT_USER = 'Ui/CountUser';
const UPDATE_COUNT_LOGIN_LOG = 'Ui/UpdateCountLoginLog';
const UPDATE_COUNT_MANUFACTURER = 'Ui/UpdateCountManufacturer';
const UPDATE_COUNT_USER = 'Ui/UpdateCountUser';
const UPDATE_LOCATION_LIST_PAGE = 'Ui/UpdateLocationListPage';
const UPDATE_LOGIN_LOG_LIST_PAGE = 'Ui/UpdateLoginLogListPage';
const UPDATE_USER_LIST_PAGE = 'Ui/UpdateUserListPage';
const UPDATE_MANUFACTURER_LIST_PAGE = 'Ui/UpdateManufacturerListPage';
const LOAD_GROUPS = 'Ui/LoadGroups';
const TOGGLE_ACTIVATION = 'Ui/ToggleActivation';
const UPDATE_USER_MODAL = 'Ui/UpdateUserModal';
const UPDATE_NEW_TASK_USER_GROUP_MODAL = 'Ui/UpdateNewTaskUserGroupModal';
const UPDATE_NEW_TASK_USER_LOCATION_MODAL = 'Ui/UpdateNewTaskUserLocationModal';
const UPDATE_NEWS_LIST_BUTTON = 'Ui/UpdateNewsListButton';
const UPDATE_TASK_LIST_BUTTON = 'Ui/UpdateTaskListButton';
const UPDATE_COMPLETED_TASK_LIST_BUTTON = 'Ui/UpdateCompletedTaskListButton';
const UPDATE_COUNT_COMPLETE_TASKS = 'Ui/UpdateCountCompleteTasks';
const UPDATE_COUNT_INCOMPLETE_TASKS = 'Ui/UpdateCountIncompleteTasks';
const COUNT_TASKS = 'Ui/CountTasks';
const UPDATE_ALERT = 'Ui/UpdateAlert';
const UPDATE_VACATION_MENU_CLASS = 'Ui/UpdateVacationMenuClass';
const UPDATE_SICK_REPORT_MENU_CLASS = 'Ui/UpdateSickReportMenuClass';
const UPDATE_TRAINING_MENU_CLASS = 'Ui/TrainingMenuClass';
const UPDATE_CURRENT_HOLIDAYS_ON_DASHBOARD = 'Ui/UpdateCurrentHolidaysOnDashboard';
const UPDATE_STOCK_LOADING_ANIMATION = 'Ui/UpdateStockLoadingAnimation';
const UPDATE_DELIVERY_CENTRAL_WAREHOUSE = 'Ui/UpdateDeliveryCentralWarehouse';
const UPDATE_DELIVERY_CENTRAL_WAREHOUSE2 = 'Ui/UpdateDeliveryCentralWarehouse2';
const UPDATE_LOGIN_LOADING_ANIMATION = 'Ui/UpdateLoginLoadingAnimation';
const CHECK_LOGIN = 'Ui/CheckLogin';
const RESET_STATE = 'Ui/ResetState';
const UPDATE_SCROLL_POSITION = 'Ui/UpdateScrollPosition';
const UPDATE_USER_SEARCH = 'Ui/UpdateUserSearch';
const UPDATE_DOCUMENT_SEARCH = 'Ui/UpdateDocumentSearch';
const UPDATE_TASK_VIEW = 'Ui/UpdateTaskView';
const UPDATE_TASK_MENU_CLASS = 'Ui/UpdateTaskMenuClass';
const UPDATE_SEEN = 'Ui/UpdateSeen';
const UPDATE_COUNT_SEEN = 'Ui/UpdateCountSeen';
const UPDATE_HEADER_OPEN = 'Ui/UpdateHeaderOpen';
const UPDATE_ABSENCE_WEEK_FILTER = 'Ui/UpdateAbsenceWeekFilter';
const UPDATE_STICKY_CLASS = 'Ui/UpdateStickyClass';
const UPDATE_CONFIRM_MODAL = 'Ui/UpdateConfirmModal';
const UPDATE_LOCATION_SPECIAL_APPOINTMENT_LIST_PAGE = 'Ui/UpdateLocationSpecialAppointmentListPage';
const UPDATE_LOCATION_SPECIAL_APPOINTMENT_LIST_COUNT = 'Ui/UpdateLocationSpecialAppointmentListCount';
const UPDATE_NEWS_MODAL_AFTER_LOGIN = 'Ui/UpdateNewsModalAfterLogin';
const UPDATE_LOCATION_SPECIAL_APPOINTMENT_VIEW_OLD = 'Ui/UpdateLocationSpecialAppointmentViewOld';
const UPDATE_LOCATION_SPECIAL_APPOINTMENT_EXISTS = 'Ui/UpdateLocationSpecialAppointmentExists';
const CHECK_EXISTING_LOCATION_SPECIAL_APPOINTMENT = 'Ui/CheckExistingLocationSpecialAppointment'
const UPDATE_CURRENT_ARTICLE_BACKGROUND_COLOR = 'Ui/UpdateCurrentArticleBackgroundColor'
const UPDATE_COLOR_PALETTE = 'Ui/UpdateColorPalette'
const SAVE_ARTICLE_BACKGROUND = 'Ui/SaveArticleBackground'
const UPDATE_COLOR_MODAL = 'Ui/UpdateColorModal'
const UPDATE_COLOR_LIST_PAGE = 'Ui/UpdateColorListPage';
const UPDATE_COLOR_COUNT = 'Ui/UpdateColorCount';

const UPDATE_CLAIM_DROPDOWN_MANUFACTURER_MODAL = 'Ui/UpdateClaimDropdownManufacturerModal'
const UPDATE_CLAIM_DROPDOWN_MANUFACTURER_LIST_PAGE = 'Ui/UpdateClaimDropdownManufacturerListPage';
const UPDATE_CLAIM_DROPDOWN_MANUFACTURER_COUNT = 'Ui/UpdateClaimDropdownManufacturerCount';

const UPDATE_CLAIM_DROPDOWN_REASON_MODAL = 'Ui/UpdateClaimDropdownReasonModal'
const UPDATE_CLAIM_DROPDOWN_REASON_LIST_PAGE = 'Ui/UpdateClaimDropdownReasonListPage';
const UPDATE_CLAIM_DROPDOWN_REASON_COUNT = 'Ui/UpdateClaimDropdownReasonCount';

const UPDATE_CLAIM_DROPDOWN_QUALITY_MODAL = 'Ui/UpdateClaimDropdownQualityModal'
const UPDATE_CLAIM_DROPDOWN_QUALITY_LIST_PAGE = 'Ui/UpdateClaimDropdownQualityListPage';
const UPDATE_CLAIM_DROPDOWN_QUALITY_COUNT = 'Ui/UpdateClaimDropdownQualityCount';
const REFRESH_TOKEN = 'Ui/RefreshToken';




const actionTypes = {
    LOGIN,
    UPDATE_USERNAME,
    UPDATE_PASSWORD,
    SHOW_LOGIN_ERROR,
    UPDATE_TOKEN,
    UPDATE_VIEW, ////
    UPDATE_NEW_TASK_VIEW, ////
    UPDATE_GRID_VIEW,
    TOGGLE_DROPDOWN,
    TOGGLE_TAB,
    UPDATE_LAZY_BUTTON,
    UPDATE_NEWS_LIST_PAGE,
    UPDATE_TASK_LIST_PAGE,
    UPDATE_COMPLETED_TASK_LIST_PAGE,
    UPDATE_LOCATION_MODAL,
    UPDATE_LOCATION_COUNT,
    COUNT_LOCATION,
    COUNT_LOGIN_LOG,
    COUNT_USER,
    COUNT_MANUFACTURER,
    UPDATE_COUNT_LOGIN_LOG,
    UPDATE_COUNT_USER,
    UPDATE_COUNT_MANUFACTURER,
    UPDATE_LOCATION_LIST_PAGE,
    UPDATE_LOGIN_LOG_LIST_PAGE,
    UPDATE_USER_LIST_PAGE,
    UPDATE_MANUFACTURER_LIST_PAGE,
    LOAD_GROUPS,
    UPDATE_MANUFACTURER_MODAL,
    TOGGLE_ACTIVATION,
    UPDATE_USER_MODAL,
    UPDATE_NEW_TASK_USER_GROUP_MODAL,
    UPDATE_NEW_TASK_USER_LOCATION_MODAL,
    UPDATE_NEWS_LIST_BUTTON,
    UPDATE_TASK_LIST_BUTTON,
    UPDATE_COMPLETED_TASK_LIST_BUTTON,
    COUNT_TASKS,
    UPDATE_COUNT_COMPLETE_TASKS,
    UPDATE_COUNT_INCOMPLETE_TASKS,
    UPDATE_ALERT,
    UPDATE_VACATION_MENU_CLASS,
    UPDATE_SICK_REPORT_MENU_CLASS,
    UPDATE_CURRENT_HOLIDAYS_ON_DASHBOARD,
    UPDATE_STOCK_LOADING_ANIMATION,
    UPDATE_DELIVERY_CENTRAL_WAREHOUSE,
    UPDATE_DELIVERY_CENTRAL_WAREHOUSE2,
    UPDATE_LOGIN_LOADING_ANIMATION,
    CHECK_LOGIN,
    RESET_STATE,
    UPDATE_SCROLL_POSITION,
    UPDATE_USER_SEARCH,
    UPDATE_DOCUMENT_SEARCH,
    UPDATE_TASK_VIEW,
    UPDATE_TASK_MENU_CLASS,
    UPDATE_SEEN,
    UPDATE_COUNT_SEEN,
    UPDATE_HEADER_OPEN,
    UPDATE_ABSENCE_WEEK_FILTER,
    UPDATE_STICKY_CLASS,
    UPDATE_CONFIRM_MODAL,
    UPDATE_LOCATION_SPECIAL_APPOINTMENT_LIST_PAGE,
    UPDATE_LOCATION_SPECIAL_APPOINTMENT_LIST_COUNT,
    UPDATE_NEWS_MODAL_AFTER_LOGIN,
    UPDATE_LOCATION_SPECIAL_APPOINTMENT_VIEW_OLD,
    UPDATE_LOCATION_SPECIAL_APPOINTMENT_EXISTS,
    CHECK_EXISTING_LOCATION_SPECIAL_APPOINTMENT,
    UPDATE_CURRENT_ARTICLE_BACKGROUND_COLOR,
    UPDATE_COLOR_PALETTE,
    SAVE_ARTICLE_BACKGROUND,
    UPDATE_COLOR_MODAL,
    UPDATE_COLOR_LIST_PAGE,
    UPDATE_COLOR_COUNT,
    UPDATE_TRAINING_MENU_CLASS,

    UPDATE_CLAIM_DROPDOWN_MANUFACTURER_MODAL,
    UPDATE_CLAIM_DROPDOWN_MANUFACTURER_LIST_PAGE,
    UPDATE_CLAIM_DROPDOWN_MANUFACTURER_COUNT,
    UPDATE_CLAIM_DROPDOWN_REASON_MODAL,
    UPDATE_CLAIM_DROPDOWN_REASON_LIST_PAGE,
    UPDATE_CLAIM_DROPDOWN_REASON_COUNT,
    UPDATE_CLAIM_DROPDOWN_QUALITY_MODAL,
    UPDATE_CLAIM_DROPDOWN_QUALITY_LIST_PAGE,
    UPDATE_CLAIM_DROPDOWN_QUALITY_COUNT,
    REFRESH_TOKEN,
};

const updateNewTicketView = createAction(UPDATE_NEW_TICKET_VIEW, (value) => ({
    value,
}));
const updateNewTaskView = createAction(UPDATE_NEW_TASK_VIEW, (value) => ({
    value,
}));
const updateView = createAction(UPDATE_VIEW, (value) => ({
    value,
}));
const showContactList = createAction(SHOW_CONTACT_LIST, (value) => ({
    value,
}));
const updateLazyButton = createAction(UPDATE_LAZY_BUTTON, (value) => ({
    value,
}));
const showLoginError = createAction(SHOW_LOGIN_ERROR, (error) => ({
    error,
}));

const updateToken = createAction(UPDATE_TOKEN, (token) => ({
    token,
}));
const updateUsername = createAction(UPDATE_USERNAME, (username) => ({
    username,
}));
const updatePassword = createAction(UPDATE_PASSWORD, (password) => ({
    password
}));
const login = createAction(LOGIN, (credentials) => ({
    credentials
}));
const updateGridView = createAction(UPDATE_GRID_VIEW, (value) => ({
    value
}));
const toggleDropdown = createAction(TOGGLE_DROPDOWN, (dropdown) => ({
    dropdown
}));
const toggleTab = createAction(TOGGLE_TAB, (tab) => ({
    tab
}));
const updateNewsListPage = createAction(UPDATE_NEWS_LIST_PAGE, (value) => ({
    value
}));
const updateTaskListPage = createAction(UPDATE_TASK_LIST_PAGE, (value) => ({
    value
}));
const updateCompletedTaskListPage = createAction(UPDATE_COMPLETED_TASK_LIST_PAGE, (value) => ({
    value
}));
const updateLocationModal = createAction(UPDATE_LOCATION_MODAL, (value) => ({
    value
}));
const updateLocationCount = createAction(UPDATE_LOCATION_COUNT, (value) => ({
    value
}));
const countLocation = createAction(COUNT_LOCATION, (value) => ({
    value
}));
const countLoginLog = createAction(COUNT_LOGIN_LOG, (value) => ({
    value
}));
const countUser = createAction(COUNT_USER, (value) => ({
    value
}));
const countManufacturer = createAction(COUNT_MANUFACTURER, (value) => ({
    value
}));
const updateCountLoginLog = createAction(UPDATE_COUNT_LOGIN_LOG, (value) => ({
    value
}));
const updateCountUser = createAction(UPDATE_COUNT_USER, (value) => ({
    value
}));
const updateCountManufacturer = createAction(UPDATE_COUNT_MANUFACTURER, (value) => ({
    value
}));
const updateLocationListPage = createAction(UPDATE_LOCATION_LIST_PAGE, (value) => ({
    value
}));
const updateLoginLogListPage = createAction(UPDATE_LOGIN_LOG_LIST_PAGE, (value) => ({
    value
}));
const updateUserListPage = createAction(UPDATE_USER_LIST_PAGE, (value) => ({
    value
}));
const updateManufacturerListPage = createAction(UPDATE_MANUFACTURER_LIST_PAGE, (value) => ({
    value
}));
const loadGroups = createAction(LOAD_GROUPS, (value) => ({
    value
}));
const updateManufacturerModal = createAction(UPDATE_MANUFACTURER_MODAL, (value) => ({
    value
}));
const toggleActivation = createAction(TOGGLE_ACTIVATION, (value) => ({
    value
}));
const updateUserModal = createAction(UPDATE_USER_MODAL, (value) => ({
    value
}));
const updateNewTaskUserGroupModal = createAction(UPDATE_NEW_TASK_USER_GROUP_MODAL, (value) => ({
    value
}));
const updateNewTaskUserLocationModal = createAction(UPDATE_NEW_TASK_USER_LOCATION_MODAL, (value) => ({
    value
}));
const updateNewsListButton = createAction(UPDATE_NEWS_LIST_BUTTON, (value) => ({
    value
}));
const updateTaskListButton = createAction(UPDATE_TASK_LIST_BUTTON, (value) => ({
    value
}));
const updateCompletedTaskListButton = createAction(UPDATE_COMPLETED_TASK_LIST_BUTTON, (value) => ({
    value
}));
const countTasks = createAction(COUNT_TASKS, (value) => ({
    value
}));
const updateCountCompleteTasks = createAction(UPDATE_COUNT_COMPLETE_TASKS, (value) => ({
    value
}));
const updateCountIncompleteTasks = createAction(UPDATE_COUNT_INCOMPLETE_TASKS, (value) => ({
    value
}));
const updateAlert = createAction(UPDATE_ALERT, (alert) => ({alert}));

const updateVacationMenuClass = createAction(UPDATE_VACATION_MENU_CLASS, (value) => ({
    value
}));
const updateSickReportMenuClass = createAction(UPDATE_SICK_REPORT_MENU_CLASS, (value) => ({
    value
}));
const updateTrainingMenuClass = createAction(UPDATE_TRAINING_MENU_CLASS, (value) => ({
    value
}));

const updateCurrentHolidaysOnDashboard = createAction(UPDATE_CURRENT_HOLIDAYS_ON_DASHBOARD, (value) => ({
    value
}));
const updateStockLoadingAnimation = createAction(UPDATE_STOCK_LOADING_ANIMATION, (value) => ({
    value
}));
const updateDeliveryCentralWarehouse = createAction(UPDATE_DELIVERY_CENTRAL_WAREHOUSE, (value) => ({
    value
}));
const updateDeliveryCentralWarehouse2 = createAction(UPDATE_DELIVERY_CENTRAL_WAREHOUSE2, (value) => ({
    value
}));
const updateLoginLoadingAnimation = createAction(UPDATE_LOGIN_LOADING_ANIMATION, (value) => ({
    value
}));
const checkLogin = createAction(CHECK_LOGIN, (status) => ({status}));
const resetState = createAction(RESET_STATE, (iState) => ({ iState }));
const updateScrollPosition = createAction(UPDATE_SCROLL_POSITION, (position) => ({ position }));
const updateUserSearch = createAction(UPDATE_USER_SEARCH, (search) => ({ search }));
const updateDocumentSearch = createAction(UPDATE_DOCUMENT_SEARCH, (search) => ({ search }));
const updateTaskView = createAction(UPDATE_TASK_VIEW, (value) => ({ value }));
const updateTaskMenuClass = createAction(UPDATE_TASK_MENU_CLASS, (value) => ({ value }));
const updateSeen = createAction(UPDATE_SEEN, page => ({ page }));
const updateCountSeen = createAction(UPDATE_COUNT_SEEN, seen => ({ seen }));
const updateHeaderOpen = createAction(UPDATE_HEADER_OPEN, value => ({ value }));
const updateAbsenceWeekFilter = createAction(UPDATE_ABSENCE_WEEK_FILTER, value => ({ value }));
const updateStickyClass = createAction(UPDATE_STICKY_CLASS, value => ({ value }));
const updateConfirmModal = createAction(UPDATE_CONFIRM_MODAL, modal => ({ modal }));
const updateLocationSpecialAppointmentListPage = createAction(UPDATE_LOCATION_SPECIAL_APPOINTMENT_LIST_PAGE, value => ({ value }));
const updateLocationSpecialAppointmentListCount = createAction(UPDATE_LOCATION_SPECIAL_APPOINTMENT_LIST_COUNT, value => ({ value }));
const updateNewsModalAfterLogin = createAction(UPDATE_NEWS_MODAL_AFTER_LOGIN, value => ({ value }));
const updateLocationSpecialAppointmentViewOld = createAction(UPDATE_LOCATION_SPECIAL_APPOINTMENT_VIEW_OLD, value => ({ value }));
const updateLocationSpecialAppointmentExists = createAction(
    UPDATE_LOCATION_SPECIAL_APPOINTMENT_EXISTS,
    value => ({value})
)
const checkExistingLocationSpecialAppointment = createAction(
    CHECK_EXISTING_LOCATION_SPECIAL_APPOINTMENT,
    value => ({value})
)

const updateCurrentArticleBackgroundColor = createAction(
    UPDATE_CURRENT_ARTICLE_BACKGROUND_COLOR,
    article => ({article})
)

const updateColorPalette = createAction(
    UPDATE_COLOR_PALETTE,
    value => ({value})
)

const saveArticleBackground = createAction(
    SAVE_ARTICLE_BACKGROUND,
    article => ({article})
)

const updateColorModal = createAction(
    UPDATE_COLOR_MODAL,
    value => ({value})
)

const updateColorListPage = createAction(
    UPDATE_COLOR_LIST_PAGE,
    value => ({value})
)
const updateColorCount = createAction(
    UPDATE_COLOR_COUNT,
    value => ({value})
)

const updateClaimDropdownManufacturerModal = createAction(UPDATE_CLAIM_DROPDOWN_MANUFACTURER_MODAL,value => ({value}))
const updateClaimDropdownManufacturerListPage = createAction(UPDATE_CLAIM_DROPDOWN_MANUFACTURER_LIST_PAGE,value => ({value}))
const updateClaimDropdownManufacturerCount = createAction(UPDATE_CLAIM_DROPDOWN_MANUFACTURER_COUNT, value => ({value}))

const updateClaimDropdownReasonModal = createAction(UPDATE_CLAIM_DROPDOWN_REASON_MODAL,value => ({value}))
const updateClaimDropdownReasonListPage = createAction(UPDATE_CLAIM_DROPDOWN_REASON_LIST_PAGE,value => ({value}))
const updateClaimDropdownReasonCount = createAction(UPDATE_CLAIM_DROPDOWN_REASON_COUNT, value => ({value}))

const updateClaimDropdownQualityModal = createAction(UPDATE_CLAIM_DROPDOWN_QUALITY_MODAL,value => ({value}))
const updateClaimDropdownQualityListPage = createAction(UPDATE_CLAIM_DROPDOWN_QUALITY_LIST_PAGE,value => ({value}))
const updateClaimDropdownQualityCount = createAction(UPDATE_CLAIM_DROPDOWN_QUALITY_COUNT, value => ({value}))
const refreshToken = createAction(REFRESH_TOKEN, value => ({value}))

const actions = {
    login,
    updateUsername,
    updatePassword,
    showLoginError,
    updateToken,
    updateGridView,
    toggleDropdown,
    toggleTab,
    updateView,
    updateNewTicketView,
    showContactList,
    updateLazyButton,
    updateNewTaskView,
    updateNewsListPage,
    updateTaskListPage,
    updateCompletedTaskListPage,
    updateLocationModal,
    updateLocationCount,
    countLocation,
    countLoginLog,
    countUser,
    countManufacturer,
    updateCountLoginLog,
    updateCountUser,
    updateCountManufacturer,
    updateLocationListPage,
    updateLoginLogListPage,
    updateUserListPage,
    updateManufacturerListPage,
    loadGroups,
    updateManufacturerModal,
    toggleActivation,
    updateUserModal,
    updateNewTaskUserGroupModal,
    updateNewTaskUserLocationModal,
    updateNewsListButton,
    updateTaskListButton,
    updateCompletedTaskListButton,
    countTasks,
    updateCountCompleteTasks,
    updateCountIncompleteTasks,
    updateAlert,
    updateVacationMenuClass,
    updateSickReportMenuClass,
    updateTrainingMenuClass,
    updateCurrentHolidaysOnDashboard,
    updateStockLoadingAnimation,
    updateDeliveryCentralWarehouse,
    updateDeliveryCentralWarehouse2,
    updateLoginLoadingAnimation,
    checkLogin,
    resetState,
    updateScrollPosition,
    updateUserSearch,
    updateDocumentSearch,
    updateTaskView,
    updateTaskMenuClass,
    updateSeen,
    updateCountSeen,
    updateHeaderOpen,
    updateAbsenceWeekFilter,
    updateStickyClass,
    updateConfirmModal,
    updateLocationSpecialAppointmentListPage,
    updateLocationSpecialAppointmentListCount,
    updateNewsModalAfterLogin,
    updateLocationSpecialAppointmentViewOld,
    updateLocationSpecialAppointmentExists,
    checkExistingLocationSpecialAppointment,
    updateCurrentArticleBackgroundColor,
    updateColorPalette,
    saveArticleBackground,
    updateColorModal,
    updateColorListPage,
    updateColorCount,
    updateClaimDropdownManufacturerModal,
    updateClaimDropdownManufacturerListPage,
    updateClaimDropdownManufacturerCount,
    updateClaimDropdownReasonModal,
    updateClaimDropdownReasonListPage,
    updateClaimDropdownReasonCount,
    updateClaimDropdownQualityModal,
    updateClaimDropdownQualityListPage,
    updateClaimDropdownQualityCount,
    refreshToken
};

const reducer = handleActions({
    [UPDATE_USERNAME]: (payload) => oldState => {
        return $set('ui.username', payload.username, oldState);
    },
    [UPDATE_PASSWORD]: (payload) => oldState => {
        return $set('ui.password', payload.password, oldState);
    },
    [SHOW_LOGIN_ERROR]: (payload) => oldState => {
        return $set('ui.showLoginError', payload.error, oldState);
    },
    [UPDATE_TOKEN]: (payload) => oldState => {
        return $set('ui.token', payload.token, oldState);
    },
    [UPDATE_GRID_VIEW]: (payload) => oldState => {
        return $set('ui.gridView', payload.value, oldState);
    },
    [TOGGLE_DROPDOWN]: (payload) => oldState => {
        return $set('ui.' + payload.dropdown.name, payload.dropdown.value, oldState);
    },
    [TOGGLE_TAB]: (payload) => oldState => {
        return $set('ui.singleArticleTab', payload.tab, oldState);
    },
    [UPDATE_VIEW]: (payload) => oldState => {
        return $set('ui.view', payload.value, oldState);
    },
    [UPDATE_NEW_TICKET_VIEW]: (payload) => oldState => {
        return $set('ui.newTicketView', payload.value, oldState);
    },
    [SHOW_CONTACT_LIST]: (payload) => oldState => {
        return $set('ui.contactList', payload.value, oldState);
    },
    [UPDATE_LAZY_BUTTON]: (payload) => oldState => {
        return $set('ui.lazyButton', payload.value, oldState);
    },
    [UPDATE_NEW_TASK_VIEW]: (payload) => oldState => {
        return $set('ui.newTaskView', payload.value, oldState);
    },
    [UPDATE_USER_LIST_PAGE]: (payload) => oldState => {
        return $set('ui.userListPage', payload.value, oldState);
    },
    [UPDATE_NEWS_LIST_PAGE]: (payload) => oldState => {
        return $set('ui.newsListPage', payload.value, oldState);
    },
    [UPDATE_TASK_LIST_PAGE]: (payload) => oldState => {
        return $set('ui.taskListPage', payload.value, oldState);
    },
    [UPDATE_COMPLETED_TASK_LIST_PAGE]: (payload) => oldState => {
        return $set('ui.completedTaskListPage', payload.value, oldState);
    },
    [UPDATE_LOCATION_MODAL]: (payload) => oldState => {
        return $set('ui.locationModal', payload.value, oldState);
    },
    [UPDATE_LOCATION_COUNT]: (payload) => oldState => {
        return $set('ui.locationCount', payload.value, oldState);
    },
    [UPDATE_COUNT_LOGIN_LOG]: (payload) => oldState => {
        return $set('ui.loginLogCount', payload.value, oldState);
    },
    [UPDATE_COUNT_USER]: (payload) => oldState => {
        return $set('ui.userCount', payload.value, oldState);
    },
    [UPDATE_COUNT_MANUFACTURER]: (payload) => oldState => {
        return $set('ui.manufacturerCount', payload.value, oldState);
    },
    [UPDATE_LOCATION_LIST_PAGE]: (payload) => oldState => {
        return $set('ui.locationListPage', payload.value, oldState);
    },
    [UPDATE_LOGIN_LOG_LIST_PAGE]: (payload) => oldState => {
        return $set('ui.loginLogListPage', payload.value, oldState);
    },
    [UPDATE_USER_LIST_PAGE]: (payload) => oldState => {
        return $set('ui.userListPage', payload.value, oldState);
    },
    [UPDATE_MANUFACTURER_LIST_PAGE]: (payload) => oldState => {
        return $set('ui.manufacturerListPage', payload.value, oldState);
    },
    [UPDATE_MANUFACTURER_MODAL]: (payload) => oldState => {
        return $set('ui.newManufacturerModal', payload.value, oldState);
    },
    [UPDATE_USER_MODAL]: (payload) => oldState => {
        return $set('ui.newUserModal', payload.value, oldState);
    },
    [UPDATE_NEW_TASK_USER_GROUP_MODAL]: (payload) => oldState => {
        return $set('ui.newTaskUserGroupDropdown', payload.value, oldState);
    },
    [UPDATE_NEW_TASK_USER_LOCATION_MODAL]: (payload) => oldState => {
        return $set('ui.newTaskUserLocationDropdown', payload.value, oldState);
    },
    [UPDATE_NEWS_LIST_BUTTON]: (payload) => oldState => {
        return $set('ui.newsListFurtherButton', payload.value, oldState);
    },
    [UPDATE_TASK_LIST_BUTTON]: (payload) => oldState => {
        return $set('ui.taskListFurtherButton', payload.value, oldState);
    },
    [UPDATE_COMPLETED_TASK_LIST_BUTTON]: (payload) => oldState => {
        return $set('ui.completedTaskListFurtherButton', payload.value, oldState);
    },
    [UPDATE_COUNT_COMPLETE_TASKS]: (payload) => oldState => {
        return $set('ui.completedTaskCount', payload.value, oldState);
    },
    [UPDATE_COUNT_INCOMPLETE_TASKS]: (payload) => oldState => {
        return $set('ui.incompleteTaskCount', payload.value, oldState);
    },
    [UPDATE_ALERT]: (payload) => oldState => {
        return $set('ui.alert', payload.alert, oldState);
    },
    [UPDATE_VACATION_MENU_CLASS]: (payload) => oldState => {
        return $set('ui.vacationMenuClass', payload.value, oldState);
    },
    [UPDATE_SICK_REPORT_MENU_CLASS]: (payload) => oldState => {
        return $set('ui.sickReportMenuClass', payload.value, oldState);
    },
    [UPDATE_TRAINING_MENU_CLASS]: (payload) => oldState => {
        return $set('ui.trainingMenuClass', payload.value, oldState);
    },
    [UPDATE_CURRENT_HOLIDAYS_ON_DASHBOARD]: (payload) => oldState => {
        return $set('ui.currentHolidaysOnDashboard', payload.value, oldState);
    },
    [UPDATE_STOCK_LOADING_ANIMATION]: (payload) => oldState => {
        return $set('ui.stockLoadingAnimation', payload.value, oldState);
    },
    [UPDATE_DELIVERY_CENTRAL_WAREHOUSE]: (payload) => oldState => {
        return $set('ui.deliveryCentralWarehouse', payload.value, oldState);
    },
    [UPDATE_DELIVERY_CENTRAL_WAREHOUSE2]: (payload) => oldState => {
        return $set('ui.deliveryCentralWarehouse2', payload.value, oldState);
    },
    [UPDATE_LOGIN_LOADING_ANIMATION]: (payload) => oldState => {
        return $set('ui.loginLoadingAnimation', payload.value, oldState);
    },
    [RESET_STATE]: (payload) => oldState => {
        initialState.token = '';
        return $set('ui', initialState, oldState);
    },
    [UPDATE_SCROLL_POSITION]: (payload) => oldState => {
        return $set('ui.scrollPosition', payload.position, oldState)
    },
    [UPDATE_USER_SEARCH]: (payload) => oldState => {
        return $set('ui.userSearch', payload.search, oldState)
    },
    [UPDATE_DOCUMENT_SEARCH]: (payload) => oldState => {
        return $set('ui.documentSearch', payload.search, oldState)
    },
    [UPDATE_TASK_VIEW]: (payload) => oldState => {
        return $set('ui.taskView', payload.value, oldState)
    },
    [UPDATE_TASK_MENU_CLASS]: (payload) => oldState => {
        return $set('ui.taskMenuClass', payload.value, oldState)
    },
    [UPDATE_COUNT_SEEN]: (payload) => oldState => {
        return $set('ui.count'+payload.seen.page, payload.seen.value, oldState)
    },
    [UPDATE_HEADER_OPEN]: (payload) => oldState => {
        return $set('ui.headerOpen', payload.value, oldState)
    },
    [UPDATE_ABSENCE_WEEK_FILTER]: (payload) => oldState => {
        return $set('ui.absenceWeekFilter', payload.value, oldState)
    },
    [UPDATE_STICKY_CLASS]: (payload) => oldState => {
        return $set('ui.stickyClass', payload.value, oldState)
    },
    [UPDATE_CONFIRM_MODAL]: (payload) => oldState => {
        return $set('ui.confirmModal.'+payload.modal.prop, payload.modal.value, oldState)
    },
    [UPDATE_LOCATION_SPECIAL_APPOINTMENT_LIST_PAGE]: (payload) => oldState => {
        return $set('ui.locationSpecialAppointmentListPage', payload.value, oldState)
    },
    [UPDATE_LOCATION_SPECIAL_APPOINTMENT_LIST_COUNT]: (payload) => oldState => {
        return $set('ui.locationSpecialAppointmentListCount', payload.value, oldState)
    },
    [UPDATE_NEWS_MODAL_AFTER_LOGIN]: (payload) => oldState => {
        return $set('ui.newsModalAfterLogin', payload.value, oldState)
    },
    [UPDATE_LOCATION_SPECIAL_APPOINTMENT_VIEW_OLD]: payload => oldState => $set('ui.locationSpecialAppointmentViewOld', payload.value, oldState),
    [UPDATE_LOCATION_SPECIAL_APPOINTMENT_EXISTS]: payload => oldState => {
        return $set('ui.locationSpecialAppointmentExists', payload.value, oldState)
    },
    [UPDATE_CURRENT_ARTICLE_BACKGROUND_COLOR]: payload => oldState => {
        return $set('ui.currentArticleBackgroundColor', payload.article, oldState)
    },
    [UPDATE_COLOR_PALETTE]: payload => oldState => {
        return $set('ui.colorPalette', payload.value, oldState)
    },
    [UPDATE_COLOR_MODAL]: payload => oldState => {
        return $set('ui.colorModal', payload.value, oldState)
    },
    [UPDATE_COLOR_LIST_PAGE]: payload => oldState => {
    return $set('ui.colorListPage', payload.value, oldState)
    },
    [UPDATE_COLOR_COUNT]: payload => oldState => {
        return $set('ui.colorCount', payload.value, oldState)
    },
    [UPDATE_CLAIM_DROPDOWN_MANUFACTURER_MODAL]: payload => oldState => {
        return $set('ui.claimDropdownManufacturerModal', payload.value, oldState)
    },
    [UPDATE_CLAIM_DROPDOWN_MANUFACTURER_LIST_PAGE]: payload => oldState => {
    return $set('ui.claimDropdownManufacturerListPage', payload.value, oldState)
    },
    [UPDATE_CLAIM_DROPDOWN_MANUFACTURER_COUNT]: payload => oldState => {
        return $set('ui.claimDropdownManufacturerCount', payload.value, oldState)
    },

    [UPDATE_CLAIM_DROPDOWN_REASON_MODAL]: payload => oldState => {
        return $set('ui.claimDropdownReasonModal', payload.value, oldState)
    },
    [UPDATE_CLAIM_DROPDOWN_REASON_LIST_PAGE]: payload => oldState => {
    return $set('ui.claimDropdownReasonListPage', payload.value, oldState)
    },
    [UPDATE_CLAIM_DROPDOWN_REASON_COUNT]: payload => oldState => {
        return $set('ui.claimDropdownReasonCount', payload.value, oldState)
    },

    [UPDATE_CLAIM_DROPDOWN_QUALITY_MODAL]: payload => oldState => {
        return $set('ui.claimDropdownQualityModal', payload.value, oldState)
    },
    [UPDATE_CLAIM_DROPDOWN_QUALITY_LIST_PAGE]: payload => oldState => {
    return $set('ui.claimDropdownQualityListPage', payload.value, oldState)
    },
    [UPDATE_CLAIM_DROPDOWN_QUALITY_COUNT]: payload => oldState => {
        return $set('ui.claimDropdownQualityCount', payload.value, oldState)
    },
});
const selectors = {
    ui: $get('ui'),
    token: $get('ui.token'),
    taskView: $get('ui.taskView'),
    userSearch: $get('ui.userSearch'),
    absenceWeekFilter: $get('ui.absenceWeekFilter'),
    documentSearch: $get('ui.documentSearch'),
    locationListPage: $get('ui.locationListPage'),
    loginLogListPage: $get('ui.loginLogListPage'),
    locationSpecialAppointmentViewOld: $get('ui.locationSpecialAppointmentViewOld'),
    locationSpecialAppointmentListPage: $get('ui.locationSpecialAppointmentListPage'),
};

export default {
    actions,
    actionTypes,
    initialState,
    reducer,
    selectors,
};
