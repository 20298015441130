import { createAction } from 'redux-actions';
import { handleActions } from '../Utility/HandleActions';
import { $get, $set } from 'plow-js';

const UPDATE_TRAINING_TO_EDIT = 'Training/UpdateTrainingToEdit';
const SAVE_TRAINING = 'Training/SaveTraining';
const LOAD_TRAINING_LIST = 'Training/LoadTrainingList';
const UPDATE_TRAINING_LIST = 'Training/UpdateTrainingList';
const DELETE_TRAINING = 'Training/DeleteTraining';
const UPDATE_OLD_LIST_VIEW = 'Training/UpdateOldListView';
const UPDATE_LIST_COUNT = 'Training/UpdateListCount';
const UPDATE_PARTICIPANT_LIST_VIEW = 'Training/UpdateParticipantListView';
const REGISTER_FOR_TRAINING = 'Training/RegisterForTraining';
const APPROVE_REGISTRATION_FOR_TRAINING = 'Training/ApproveRegistrationForTraining';
const DECLINE_REGISTRATION_FOR_TRAINING = 'Training/DeclineRegistrationForTraining';
const UPDATE_LOADING_ANIMATION = 'Training/UpdateLoadingAnimation';
const UPDATE_CURRENT_TRAINING = 'Training/UpdateCurrentTraining';
const UPDATE_VALUATION = 'Training/UpdateValuation';
const ADD_VALUATION = 'Training/AddValuation';
const RESET_STATE = 'Training/ResetState';
const UPDATE_ALL_USERS_FOR_TRAINING = 'Training/UpdateAllUsersForTraining';
const LOAD_ALL_USERS_FOR_TRAINING = 'Training/LoadAllUsersForTraining';
const LOAD_USER_TRAININGS = 'Training/LoadUserTrainings';
const UPDATE_USER_TRAININGS = 'Training/UpdateUserTrainings';
const UPDATE_REFERENCE = 'Training/UpdateTrainingReference';
const ADD_NEW_RATING = 'Training/AddNewRating';
const DELETE_RATING = 'Training/DeleteRating';
const UPDATE_VALUATION_REFERENCE = 'Training/UpdateValuationReference';
const UPDATE_ACTIVE_TAB = 'Training/UpdateActiveTab';
const UPDATE_TRAINING_VIEW = 'Training/UpdateTrainingView';
const LOAD_OVERVIEW_LIST = 'Training/LoadOverviewList';
const UPDATE_OVERVIEW_LIST = 'Training/UpdateOverviewList';
const UPDATE_REGISTER_STATUS = 'Training/UpdatedRegisterStatus';
const UPDATE_REGISTER_MESSAGE = 'Training/UpdateRegisterMessage';
const UPDATE_FORCE_REGISTER = 'Training/UpdateForceRegister';



const actionTypes = {
    UPDATE_TRAINING_TO_EDIT,
    SAVE_TRAINING,
    LOAD_TRAINING_LIST,
    UPDATE_TRAINING_LIST,
    DELETE_TRAINING,
    UPDATE_OLD_LIST_VIEW,
    UPDATE_LIST_COUNT,
    UPDATE_PARTICIPANT_LIST_VIEW,
    REGISTER_FOR_TRAINING,
    APPROVE_REGISTRATION_FOR_TRAINING,
    DECLINE_REGISTRATION_FOR_TRAINING,
    UPDATE_LOADING_ANIMATION,
    UPDATE_CURRENT_TRAINING,
    UPDATE_VALUATION,
    ADD_VALUATION,
    RESET_STATE,
    UPDATE_ALL_USERS_FOR_TRAINING,
    LOAD_ALL_USERS_FOR_TRAINING,
    LOAD_USER_TRAININGS,
    UPDATE_USER_TRAININGS,
    UPDATE_REFERENCE,
    ADD_NEW_RATING,
    DELETE_RATING,
    UPDATE_VALUATION_REFERENCE,
    UPDATE_ACTIVE_TAB,
    UPDATE_TRAINING_VIEW,
    LOAD_OVERVIEW_LIST,
    UPDATE_REGISTER_STATUS,
    UPDATE_REGISTER_MESSAGE,
    UPDATE_FORCE_REGISTER,
};

const updateTrainingToEdit = createAction(UPDATE_TRAINING_TO_EDIT, (training) => ({training}));
const saveTraining = createAction(SAVE_TRAINING, (value) => ({value}));
const loadTrainingList = createAction(LOAD_TRAINING_LIST, (list) => ({list}));
const updateTrainingList = createAction(UPDATE_TRAINING_LIST, (list) => ({list}));
const deleteTraining = createAction(DELETE_TRAINING, (training) => ({training}));
const updateOldListView = createAction(UPDATE_OLD_LIST_VIEW, (value) => ({value}));
const updateListCount = createAction(UPDATE_LIST_COUNT, (count) => ({count}));
const updateParticipatListView = createAction(UPDATE_PARTICIPANT_LIST_VIEW, (training) => ({training}));
const registerForTraining = createAction(REGISTER_FOR_TRAINING, (training) => ({training}));
const approveRegistrationForTraining = createAction(APPROVE_REGISTRATION_FOR_TRAINING, (appointment) => ({appointment}));
const declineRegistrationForTraining = createAction(DECLINE_REGISTRATION_FOR_TRAINING, (appointment) => ({appointment}));
const updateLoadingAnimation = createAction(UPDATE_LOADING_ANIMATION, (value) => ({value}));
const updateCurrentTraining = createAction(UPDATE_CURRENT_TRAINING, (training) => ({training}));
const updateValuation = createAction(UPDATE_VALUATION, (valuation) => ({valuation}));
const addValuation = createAction(ADD_VALUATION, (valuation) => ({valuation}));
const resetState = createAction(RESET_STATE, (iState) => ({ iState }));
const updateAllUsersForTraining = createAction(UPDATE_ALL_USERS_FOR_TRAINING, (list) => ({ list }));
const loadAllUsersForTraining = createAction(LOAD_ALL_USERS_FOR_TRAINING, (list) => ({ list }));
const loadUserTrainings = createAction(LOAD_USER_TRAININGS, (value) => ({ value }));
const updateUserTrainings = createAction(UPDATE_USER_TRAININGS, (list) => ({ list }));
const updateReference = createAction(UPDATE_REFERENCE, (value) => ({ value }));
const addNewRating = createAction(ADD_NEW_RATING, (value) => ({ value }));
const deleteRating = createAction(DELETE_RATING, (value) => ({ value }));
const updateValuationReference = createAction(UPDATE_VALUATION_REFERENCE, (value) => ({ value }));
const updateActiveTab = createAction(UPDATE_ACTIVE_TAB, (value) => ({ value }));
const updateTrainingView = createAction(UPDATE_TRAINING_VIEW, (value) => ({ value }));
const loadOverviewList = createAction(LOAD_OVERVIEW_LIST, (value) => ({ value }));
const updateOverviewList = createAction(UPDATE_OVERVIEW_LIST, (list) => ({ list }));
const updateRegisterStatus = createAction(UPDATE_REGISTER_STATUS, (value) => ({ value }));
const updateRegisterMessage = createAction(UPDATE_REGISTER_MESSAGE, (value) => ({ value }));
const updateForceRegister = createAction(UPDATE_FORCE_REGISTER, (value) => ({ value }));


const actions = {
    updateTrainingToEdit,
    saveTraining,
    loadTrainingList,
    updateTrainingList,
    deleteTraining,
    updateOldListView,
    updateListCount,
    updateParticipatListView,
    registerForTraining,
    approveRegistrationForTraining,
    declineRegistrationForTraining,
    updateLoadingAnimation,
    updateCurrentTraining,
    updateValuation,
    addValuation,
    resetState,
    updateAllUsersForTraining,
    loadAllUsersForTraining,
    loadUserTrainings,
    updateUserTrainings,
    updateReference,
    addNewRating,
    deleteRating,
    updateValuationReference,
    updateActiveTab,
    updateTrainingView,
    loadOverviewList,
    updateOverviewList,
    updateRegisterStatus,
    updateRegisterMessage,
    updateForceRegister
};

const initialState = {
    trainingToEdit: {
        city: 'Verwaltung',
        address: 'Rosenkamp 10',
        zip: '42549',
        ratings: [
            {
                'type': 'Frage',
                'label': '',
                'value': ''
            }
        ]
    },
    list: [],
    oldList: [],
    oldListView: false,
    listCount: 0,
    oldListCount: 0,
    loadingAnimation: false,
    currentTraining: null,
    valuation: {},
    allUsersForTraining: [],
    userTrainings: [],
    reference: '',
    categories:[
        { label: 'Schulungen' },
        { label: 'Termine' },
        { label: 'Sonstiges' }
    ],
    activeTab: 0,
    valuationReference: false,
    view: 2,
    overviewList: [],
    registerStatus: 200,
    registerMessage: '',
    forceRegister: '',
};

const reducer = handleActions({
    [UPDATE_TRAINING_TO_EDIT]: (payload) => oldState => {
        if(payload.training.prop === 'resetObj') {
            return $set('training.trainingToEdit', {...initialState.trainingToEdit}, oldState);
        } else {
            return $set('training.trainingToEdit.' + payload.training.prop, payload.training.val, oldState);
        }
    },
    [UPDATE_TRAINING_LIST]: (payload) => oldState => {
        return $set('training.'+payload.list.path, payload.list.list, oldState);
    },
    [DELETE_TRAINING]: (payload) => oldState => {
        let list = [...oldState.training[payload.training.path]];
        list = list.filter(training => training.id !== payload.training.training.id)
        return $set('training.'+payload.training.path, list, oldState);
    },
    [UPDATE_OLD_LIST_VIEW]: (payload) => oldState => {
        return $set('training.oldListView', payload.value, oldState);
    },
    [UPDATE_LIST_COUNT]: (payload) => oldState => {
        return $set('training.'+payload.count.path, payload.count.count, oldState);
    },
    [UPDATE_PARTICIPANT_LIST_VIEW]: (payload) => oldState => {
        let list = [...oldState.training[payload.training.path]].map((training, index) => {
            if (payload.training.id === training.id) {
                training.showList = payload.training.showList;
            }
            return training;
        });
        return $set('training.'+payload.training.path, list, oldState);
    },
    [APPROVE_REGISTRATION_FOR_TRAINING]: (payload) => oldState => {
        let list = [...oldState.training.list].map((training) => {
            if (payload.appointment.trainingId === training.id) {
                training.appointments[payload.appointment.index].approved = true;
                training.appointments[payload.appointment.index].waitingList = false;
            }
            return training;
        });
        return $set('training.list', list, oldState);
    },
    [DECLINE_REGISTRATION_FOR_TRAINING]: (payload) => oldState => {
        let list = [...oldState.training.list].map((training) => {
            if (payload.appointment.trainingId === training.id) {
                training.appointments.splice(payload.appointment.index, 1);
            }
            return training;
        });
        return $set('training.list', list, oldState);
    },
    [UPDATE_LOADING_ANIMATION]: (payload) => oldState => {
        return $set('training.loadingAnimation', payload.value, oldState);
    },
    [UPDATE_CURRENT_TRAINING]: (payload) => oldState => {
        return $set('training.currentTraining', payload.training, oldState);
    },
    [UPDATE_VALUATION]: (payload) => oldState => {
        if(payload.valuation.prop === 'resetObj') {
            return $set('training.valuation', {}, oldState);
        } else {
            return $set('training.valuation.' + payload.valuation.prop, payload.valuation.val, oldState);
        }
    },
    [RESET_STATE]: (payload) => oldState => {
        return $set('training', initialState, oldState);
    },
    [UPDATE_ALL_USERS_FOR_TRAINING]: (payload) => oldState => {
        return $set('training.allUsersForTraining', payload.list, oldState)
    },
    [UPDATE_USER_TRAININGS]: (payload) => oldState => {
        return $set('training.userTrainings', payload.list, oldState)
    },
    [UPDATE_REFERENCE]: (payload) => oldState => {
        return $set('training.reference', payload.value, oldState);
    },
    [ADD_NEW_RATING]: (payload) => oldState => {
        let list = [...oldState.training.trainingToEdit.ratings]
        list.push({
            'type': 'Frage',
            'label': '',
            'value': ''
        })
        return $set('training.trainingToEdit.ratings', list, oldState);
    },

    [DELETE_RATING]: (payload) => oldState => {
        let list = [...oldState.training.trainingToEdit.ratings];
        list = list.filter((rating,index) => index !== payload.value)
        return $set('training.trainingToEdit.ratings', list, oldState);

    },
    [UPDATE_VALUATION_REFERENCE]: ({value}) => oldState => {
        return $set('training.valuationReference', value, oldState);
    },
    [UPDATE_ACTIVE_TAB]: ({value}) => oldState => {
        return $set('training.activeTab', value, oldState);
    },
    [UPDATE_TRAINING_VIEW]: ({value}) => oldState => {
        return $set('training.view', value, oldState);
    },
    [UPDATE_OVERVIEW_LIST]: ({list}) => oldState => {
        return $set('training.overviewList', list, oldState);
    },
    [UPDATE_REGISTER_STATUS]: ({value}) => oldState => {
        return $set('training.registerStatus', value, oldState);
    },
    [UPDATE_REGISTER_MESSAGE]: ({value}) => oldState => {
        return $set('training.registerMessage', value, oldState);
    },
    [UPDATE_FORCE_REGISTER]: ({value}) => oldState => {
        return $set('training.forceRegister', value, oldState);
    },

});

const selectors = {
    training: $get('training'),
};

export default {
    actions,
    actionTypes,
    initialState,
    reducer,
    selectors,
};
