import DateInput from 'Components/UI/Form/DateInput';
import * as PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { findDOMNode } from 'react-dom';
import { connect } from 'react-redux';
import Breadcrumb from '../Components/UI/Breadcrumb';
import { actions } from '../Redux/index';
import FilterDropdown from './FilterDropdown';
import { crop, permissionToEditAbsenceList} from './Util';
import IconLink from '../Components/UI/IconLink';
import { host } from '../Sagas/host';
import Icon from '../Components/UI/Icon/Icon';
import * as moment from 'moment';
import '../assets/styles/_task.scss';

const mapStateToProps = (state) => {
    return {
        calendar: state.data.calendar,
        currentUser: state.data.currentUser,
        absenceDate: state.data.absenceDate,
        locationFilter: state.data.filter.absencerecord.location,
        allLocations: state.data.allLocations,
        selectedLocation: state.data.filter.absencerecord.location,
        token: state.ui.token,
        locationInfo: state.data.locationInfo,
        calendarUserModal: state.data.modal.absence.calendarUser,
        calendarUser: state.data.calendarUser,
        scrollPosition: state.ui.scrollPosition,
        calendarLoading: state.absence.calendarLoading,
        userSearch: state.ui.userSearch,
        absenceWeekFilter: state.ui.absenceWeekFilter,
        holidayApi: state.data.holidayApi

    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateCalendar: (list) => dispatch(actions.Data.updateCalendar(list)),
        updateView: (value) => dispatch(actions.Ui.updateView(value)),
        updateCalendarData: (data) => dispatch(actions.Data.updateCalendarData(data)),
        updateAbsenceDate: (date) => dispatch(actions.Data.updateAbsenceDate(date)),
        updateFilter: (filter) => dispatch(actions.Data.updateFilter(filter)),
        loadCalendar: (list) => dispatch(actions.Data.loadCalendar(list)),
        updateAbsenceRequest: (absence) => dispatch(actions.Absence.updateAbsenceRequest(absence)),
        updatePrevView: (view) => dispatch(actions.Absence.updatePrevView(view)),
        updateLocationInfo: (locationInfo) => dispatch(actions.Data.updateLocationInfo(locationInfo)),
        updateModal: (modal) => dispatch(actions.Data.updateModal(modal)),
        updateCalendarUser: (calendarUser) => dispatch(actions.Data.updateCalendarUser(calendarUser)),
        updateCalendarWeek: (date) => dispatch(actions.Data.updateCalendarWeek(date)),
        updateScrollPosition: (position) => dispatch(actions.Ui.updateScrollPosition(position)),
        updateUserSearch: (search) => dispatch(actions.Ui.updateUserSearch(search)),
        updateAbsenceWeekFilter: (value) => dispatch(actions.Ui.updateAbsenceWeekFilter(value)),
    };
};


class AbsenceRecord extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            offset: 0,
            specialAppointmentPopup: null,
        }
    }

    static propTypes = {
        updateView: PropTypes.func.isRequired,
        updateCalendar: PropTypes.func,
        calendar: PropTypes.array,
        allLocations: PropTypes.array,
        updateAbsenceDate: PropTypes.func.isRequired,
        updateFilter: PropTypes.func.isRequired,
        loadCalendar: PropTypes.func.isRequired,
        updateAbsenceRequest: PropTypes.func.isRequired,
        updatePrevView: PropTypes.func.isRequired,
        updateCalendarWeek: PropTypes.func.isRequired,
        updateScrollPosition: PropTypes.func.isRequired,
        updateAbsenceWeekFilter: PropTypes.func.isRequired,
    };




    getEmployeeStatusClass(status) {
        let cls = '';
        switch(status){
        case 2: cls = 'red-bg'; return cls;
        case 3: cls = 'blue-bg'; return cls;
        case 4: cls = 'yellow-bg'; return cls;
        case 5: cls = 'white-status-bg'; return cls;
        case 6: cls = 'yellow-bg'; return cls;
        case 7: cls = 'green-bg'; return cls;
        case 71: cls = 'green-bg'; return cls;
        case 72: cls = 'white-status-bg-present'; return cls;
        case 9: cls = 'orange-bg'; return cls;
        case 11: cls = 'yellow-bg'; return cls;
        case 12: cls = 'yellow-bg'; return cls;
        case 22: cls = 'green-bg';  return cls;
        case 31: cls = 'yellow-bg';  return cls;
        case 32: cls = 'orange-bg';  return cls;
        case 33: cls = 'blue-bg';  return cls;
        case 34: cls = 'blue-bg';  return cls;
        case 35: cls = 'red-bg';  return cls;
        case 36: cls = 'red-bg';  return cls;
        case 37: cls = 'yellow-bg';  return cls;
        case 38: cls = 'yellow-bg';  return cls;
        case 39: cls = 'yellow-bg';  return cls;
        case 40: cls = 'yellow-bg';  return cls;
        case 41: cls = 'blue-bg';  return cls;
        case 42: cls = 'blue-bg';  return cls;
        case 43: cls = 'yellow-bg';  return cls;
        case 44: cls = 'yellow-bg';  return cls;
        case 45: cls = 'yellow-bg';  return cls;
        case 46: cls = 'red-bg';  return cls;
        case 47: cls = 'red-bg';  return cls;
        default: return cls;
        }
    }

    countByStatusAndDate(status, date) {
        const currentDate = this.props.calendar[Number(date)]
        if(status === 'Anwesend') {
            return currentDate.users.filter(user => {
                return [4,7,22,71,72,9,31,32].includes(user.status)
            }).length
        }
        if(status === 'Ganztägig') {
            return currentDate.users.filter(user => {
                return [4,7].includes(user.status)
            }).length
        }
        if(status === 'Früh') {
            return currentDate.users.filter(user => {
                return [71].includes(user.status)
            }).length
        }
        if(status === 'Spät') {
            return currentDate.users.filter(user => {
                return [9,72].includes(user.status)
            }).length
        }
        if(status === 'Abwesend') {
            console.log(currentDate.users)
            return currentDate.users.filter(user => {
                return [1,2,3,5,6,12,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47].includes(user.status)
            }).length
        }

        return 0
    }

    getHolidayName(date) {
        let holidayName = ''
        let isVacation = false
        const holidays = this.props.holidayApi.holidays
        const holidaysLastYear = this.props.holidayApi.holidaysLastYear
        const holidaysNextYear = this.props.holidayApi.holidaysNextYear
        const vacation = this.props.holidayApi.vacation
        const vacationLastYear = this.props.holidayApi.vacationLastYear
        const vacationNextYear = this.props.holidayApi.vacationNextYear
        if (holidaysLastYear['Neujahrstag']) {
            for (let prop in holidaysLastYear) {
                if (holidaysLastYear[prop]['datum'] === date) {
                    return {holidayName: prop, holiday: true}
                }
            }
        }
        if (holidays['Neujahrstag']) {
            for (let prop in holidays) {
                if (holidays[prop]['datum'] === date) {
                    return {holidayName: prop, holiday: true}
                }
            }
        }
        if (holidaysNextYear['Neujahrstag']) {
            for (let prop in holidaysNextYear) {
                if (holidaysNextYear[prop]['datum'] === date) {
                    return {holidayName: prop, holiday: true}
                }
            }
        }
        if (vacation.length) {
            vacation.forEach(voc => {
                if (moment(date).isBetween(voc.start, voc.end)) {
                    holidayName = voc.name
                    isVacation = true
                }
            })
        }
        if (vacationLastYear.length) {
            vacationLastYear.forEach(voc => {
                if (moment(date).isBetween(voc.start, voc.end)) {
                    holidayName = voc.name
                    isVacation = true
                }
            })
        }
        if (vacationNextYear.length) {
            vacationNextYear.forEach(voc => {
                if (moment(date).isBetween(voc.start, voc.end)) {
                    holidayName = voc.name
                    isVacation = true
                }
            })
        }
        return {holidayName, isVacation}
    }

    getCalendar() {
        if (this.props.calendar[0].users) {
            return (
                <React.Fragment>
                    <div className="table-content-1 black" key={"info-index"}>
                        <span>Info</span>
                    </div>
                {this.props.calendar[0].users.map((employee, index) => (
                    <div className={employee.divider === true ? 'table-content-1 black with-divider' : 'table-content-1 black'} key={index}>
                        <span>{employee.firstNameShort} {employee.lastName}</span>
                    </div>
                ))}
                </React.Fragment>
            )
        }

    }
    scrollToActiveClass() {
        const node = findDOMNode(this);
        if (node instanceof HTMLElement) {
            const child = node.querySelector('.active-date');
            if (child) {
                const scroll = this.getOffset(child).left - this.getOffset(this.refs.absence).left;
                console.log(this.getOffset(child));
                console.log(scroll);
                this.refs.absence.scrollLeft = this.getOffset(child).left - this.getOffset(this.refs.absence).left;
            }
        }
    }

    UNSAFE_componentWillReceiveProps() {
       // this.scrollToActiveClass()
    }

    getOffset(el) {
        const rect = el.getBoundingClientRect();
        return {
            left: rect.left + window.scrollX,
            top: rect.top + window.scrollY
        };
    }

    getLink()  {
        let location = '';
        if (this.props.selectedLocation.selectedId !== '') {
            location = this.props.selectedLocation.selectedId;
        }else if (this.props.currentUser.location) {
            location = this.props.currentUser.currentLocation.id;
        }
        if (this.props.absenceDate) {
            return host + "/exportcalendar/"+location+"/"+this.props.absenceDate;
        } else {
            return host + "/exportcalendar/"+location;
        }
    }

    can (...args) {
        return args.some( permission => this.props.currentUser.permissions.includes(permission))
    }

    getStatusLabel(user) {
        /**
         * stati die nicht vorhanden sind wurden im laufe der Zeit entfernt.
         * 7 ist default und ist bedeutet Anwesend
         */
        switch(user.status) {
            case 2: return 'KR';
            case 3: return 'UR';
            case 4: return user.statusLabel;
            case 5: return 'frei';
            case 6: return 'Veranstaltung';
            case 9: return 'BS/X';
            case 11: return 'S/F';
            case 12: return 'BS/Frei';
            case 22: return user.statusLabel
            case 71: return 'Früh'
            case 72: return 'Spät'
            case 31: return 'BS/G'
            case 32: return 'BS/V'
            case 33: return 'FA'
            case 34: return 'MA'
            case 35: return 'KO'
            case 36: return 'KR'
            case 37: return 'EZ'
            case 38: return 'FS'
            case 39: return 'UA'
            case 40: return 'WB'
            case 41: return 'UL'
            case 42: return 'SU'
            case 43: return 'RG'
            case 44: return 'GF'
            case 45: return 'FT'
            case 46: return 'KK'
            case 47: return 'KL'
            default: return (<em className="fa fa-check" aria-hidden="true"/>)
        }
    }
    getTitle(user){
        const info = user.trainingInfo ? ` - "${user.trainingInfo}"` : ''
        if (this.can('an_abwesenheit_erweiterten_titel_anzeigen')) {
            if (user.specialHoliday) {
                return user.reason;
            } else if(user.overtimeDeduction) {
                return user.overtime;
            } else if(user.training) {
                return `${user.training} ${user.trainingTime}${info}`;
            }
        } else {
            if(user.training) {

                return `${user.training} ${user.trainingTime}${info}`;
            } else {
                return '';
            }
        }
    }


    getWeek(week) {
        if (week === 'last') {
            return (<i style={{'marginRight': '15px'}}
                       className="fa fa-angle-double-left week-btn"
                       aria-hidden="true"
                       onClick={() => {
                            this.props.updateCalendarWeek(moment(this.props.calendar[0].dt).subtract(1, 'w').format('YYYY-MM-DD'))
                       }}
                    />)
        }else if(week === 'next') {
            return (<i style={{'marginLeft': '15px'}}
                    className="fa fa-angle-double-right week-btn"
                    aria-hidden="true"
                    onClick={() => {
                        this.props.updateCalendarWeek(moment(this.props.calendar[0].dt).add(1, 'w').format('YYYY-MM-DD'))
                   }}
            />)
        }
    }

    componentWillMount() {
        this.props.updateUserSearch('')
        this.props.loadCalendar(true)
        this.props.updateAbsenceWeekFilter('')
    }
    componentDidMount() {
     window.scrollInterval = window.setInterval(() => {
            const offset = 255
            const off = window.scrollY-offset > 0 ? window.scrollY-offset : 0;
            if(this.state.offset !== off) {
                 this.setState({
                     ...this.state,
                     offset: off
                    })
            }
        }, 800);
    }

    componentWillUnmount() {
        clearInterval(window.scrollInterval)
    }

    setSpecialAppointmentPopup(index) {
        this.setState(
            {
                ...this.state,
                specialAppointmentPopup : index
            }
        )
    }

    renderCalendarUserModalInfoForm() {
        return (
            <form>
                <div className="content-wrapper content-form">
                    <div className="content-wrapper-box">
                        <div className="grid-wrapper absenceButton">
                            <div className="small-12 medium-12 large-12">
                                <textarea
                                    cols="30"
                                    rows="7"
                                    value={this.props.calendarUser.user.info}
                                    placeholder={this.props.calendarUser.user.isAppointment ? "Termine eintragen" : "Info eintragen"}
                                    onChange={e => {
                                        const currentUser = {...this.props.calendarUser}
                                        currentUser.user.info = e.target.value
                                        this.props.updateCalendarUser(currentUser);
                                    }}
                                    onKeyUp={e => {
                                        if(e.keyCode === 13) {
                                            this.props.updateCalendarData({dateIndex: this.props.calendarUser.dateIndex, user: this.props.calendarUser.user, userIndex:this.props.calendarUser.userIndex, prop:'info', val: e.target.value});
                                            this.props.updateModal({path: 'absence.calendarUser', prop: 'view', val: !this.props.calendarUserModal.view});
                                            this.props.updateCalendarUser({});
                                        }
                                    }}
                                />
                            </div>
                            <div className="small-12 medium-12 large-12">
                                <span>
                                    {this.props.calendarUser.user.firstNameShort} {this.props.calendarUser.user.lastName}, {this.props.calendarUser.date} <br/>
                                    Info: {this.props.calendarUser.user.info}
                                </span>
                                <br/>
                                Anwesend: {this.countByStatusAndDate('Anwesend', this.props.calendarUser.dateIndex)}<br/>
                                Ganztägig: {this.countByStatusAndDate('Ganztägig', this.props.calendarUser.dateIndex)}<br/>
                                Früh: {this.countByStatusAndDate('Früh', this.props.calendarUser.dateIndex)}<br />
                                Spät (Spät + BS/X): {this.countByStatusAndDate('Spät', this.props.calendarUser.dateIndex)}<br/>
                                Abwesend (Krank, Urlaub, Frei, BS/Frei, Veranstaltung): {this.countByStatusAndDate('Abwesend', this.props.calendarUser.dateIndex)} <br/>

                            </div>
                        </div>
                    </div>
                </div>
            </form>
        )
    }

    renderDivButton(value, text, name){
        return (
            <div className="small-12 medium-6 large-6">
                <button className={name} onClick={e => {e.preventDefault()
                    this.props.updateCalendarData({dateIndex: this.props.calendarUser.dateIndex, user: this.props.calendarUser.user, userIndex:this.props.calendarUser.userIndex, prop:'statusLabel', val: text});
                    this.props.updateCalendarData({dateIndex: this.props.calendarUser.dateIndex, user: this.props.calendarUser.user, userIndex:this.props.calendarUser.userIndex, prop:'status', val: value});
                    this.props.updateModal({path: 'absence.calendarUser', prop: 'view', val: !this.props.calendarUserModal.view});
                    this.props.updateCalendarUser({});
                }}>{text}</button>
            </div>
        )

    }

    renderCalendarUserModalForm() {
        return (
            <form>
                <div className="content-wrapper content-form">
                    <div className="content-wrapper-box">
                        <div className="grid-wrapper absenceButton">
                            { this.renderDivButton(7, 'Anwesend', 'present')}
                            {this.renderDivButton(71, 'Frühschicht', 'present')}
                            {this.renderDivButton(72, 'Spätschicht', 'present')}
                            {this.renderDivButton(2, 'Krank', 'absence')}
                            {this.renderDivButton(3, 'Urlaub', 'vacation')}
                            {this.renderDivButton(9, 'BS/X', 'school')}
                            {this.renderDivButton(5, 'Frei', 'free')}
                            {this.renderDivButton(12, 'BS/Frei', 'absenceReason')}
                            {this.renderDivButton(11, 'S/F', 'absenceReason')}

                            {/*<div className="small-12 medium-6 large-6">*/}
                            {/*    <button className="present" onClick={e => {e.preventDefault()*/}
                            {/*        this.props.updateCalendarData({dateIndex: this.props.calendarUser.dateIndex, user: this.props.calendarUser.user, userIndex:this.props.calendarUser.userIndex, prop:'statusLabel', val: 'anwesend'});*/}
                            {/*        this.props.updateCalendarData({dateIndex: this.props.calendarUser.dateIndex, user: this.props.calendarUser.user, userIndex:this.props.calendarUser.userIndex, prop:'status', val: 7});*/}
                            {/*        this.props.updateModal({path: 'absence.calendarUser', prop: 'view', val: !this.props.calendarUserModal.view});*/}
                            {/*        this.props.updateCalendarUser({});*/}
                            {/*    }}>Anwesend</button>*/}
                            {/*</div>*/}
                            {/*<div className="small-12 medium-6 large-6">*/}
                            {/*    <button className="absence" onClick={e => {e.preventDefault()*/}
                            {/*        this.props.updateCalendarData({dateIndex: this.props.calendarUser.dateIndex, user: this.props.calendarUser.user, userIndex:this.props.calendarUser.userIndex, prop:'statusLabel', val: 'Krank'});*/}
                            {/*        this.props.updateCalendarData({dateIndex: this.props.calendarUser.dateIndex, user: this.props.calendarUser.user, userIndex:this.props.calendarUser.userIndex, prop:'status', val: 2});*/}
                            {/*        this.props.updateModal({path: 'absence.calendarUser', prop: 'view', val: !this.props.calendarUserModal.view});*/}
                            {/*        this.props.updateCalendarUser({});*/}
                            {/*    }}>Krank</button>*/}
                            {/*</div>*/}
                            {/*<div className="small-12 medium-6 large-6">*/}
                            {/*    <button className="vacation" onClick={e => {e.preventDefault()*/}
                            {/*        this.props.updateCalendarData({dateIndex: this.props.calendarUser.dateIndex, user: this.props.calendarUser.user, userIndex:this.props.calendarUser.userIndex, prop:'statusLabel', val: 'Urlaub'});*/}
                            {/*        this.props.updateCalendarData({dateIndex: this.props.calendarUser.dateIndex, user: this.props.calendarUser.user, userIndex:this.props.calendarUser.userIndex, prop:'status', val: 3});*/}
                            {/*        this.props.updateModal({path: 'absence.calendarUser', prop: 'view', val: !this.props.calendarUserModal.view});*/}
                            {/*        this.props.updateCalendarUser({});*/}
                            {/*    }}>Urlaub</button>*/}
                            {/*</div>*/}
                            {/*<div className="small-12 medium-6 large-6">*/}
                            {/*    <button className="school" onClick={e => {e.preventDefault()*/}
                            {/*        this.props.updateCalendarData({dateIndex: this.props.calendarUser.dateIndex, user: this.props.calendarUser.user, userIndex:this.props.calendarUser.userIndex, prop:'statusLabel', val: 'BS/X'});*/}
                            {/*        this.props.updateCalendarData({dateIndex: this.props.calendarUser.dateIndex, user: this.props.calendarUser.user, userIndex:this.props.calendarUser.userIndex, prop:'status', val: 9});*/}
                            {/*        this.props.updateModal({path: 'absence.calendarUser', prop: 'view', val: !this.props.calendarUserModal.view});*/}
                            {/*        this.props.updateCalendarUser({});*/}
                            {/*    }}>BS/X</button>*/}
                            {/*</div>*/}
                            {/*<div className="small-12 medium-6 large-6">*/}
                            {/*    <button className="free" onClick={e => {e.preventDefault()*/}
                            {/*        this.props.updateCalendarData({dateIndex: this.props.calendarUser.dateIndex, user: this.props.calendarUser.user, userIndex:this.props.calendarUser.userIndex, prop:'statusLabel', val: 'frei'});*/}
                            {/*        this.props.updateCalendarData({dateIndex: this.props.calendarUser.dateIndex, user: this.props.calendarUser.user, userIndex:this.props.calendarUser.userIndex, prop:'status', val: 5});*/}
                            {/*        this.props.updateModal({path: 'absence.calendarUser', prop: 'view', val: !this.props.calendarUserModal.view});*/}
                            {/*        this.props.updateCalendarUser({});*/}
                            {/*    }}>Frei</button>*/}
                            {/*</div>*/}
                            {/*<div className="small-12 medium-6 large-6">*/}
                            {/*    <button className="absenceReason" onClick={e => {e.preventDefault()*/}
                            {/*        this.props.updateCalendarData({dateIndex: this.props.calendarUser.dateIndex, user: this.props.calendarUser.user, userIndex:this.props.calendarUser.userIndex, prop:'statusLabel', val: 'BS/Frei'});*/}
                            {/*        this.props.updateCalendarData({dateIndex: this.props.calendarUser.dateIndex, user: this.props.calendarUser.user, userIndex:this.props.calendarUser.userIndex, prop:'status', val: 12});*/}
                            {/*        this.props.updateModal({path: 'absence.calendarUser', prop: 'view', val: !this.props.calendarUserModal.view});*/}
                            {/*        this.props.updateCalendarUser({});*/}
                            {/*    }}>BS/Frei</button>*/}
                            {/*</div>*/}
                            {/*<div className="small-12 medium-6 large-6">*/}
                            {/*    <button className="absenceReason" onClick={e => {e.preventDefault()*/}
                            {/*        this.props.updateCalendarData({dateIndex: this.props.calendarUser.dateIndex, user: this.props.calendarUser.user, userIndex:this.props.calendarUser.userIndex, prop:'statusLabel', val: 'S/F'});*/}
                            {/*        this.props.updateCalendarData({dateIndex: this.props.calendarUser.dateIndex, user: this.props.calendarUser.user, userIndex:this.props.calendarUser.userIndex, prop:'status', val: 11});*/}
                            {/*        this.props.updateModal({path: 'absence.calendarUser', prop: 'view', val: !this.props.calendarUserModal.view});*/}
                            {/*        this.props.updateCalendarUser({});*/}
                            {/*    }}>S/F</button>*/}
                            {/*</div>*/}
                            <div className="small-12 medium-6 large-6">
                                <button className="absenceReason" onClick={e => {e.preventDefault()}}><input
                                    type="text" placeholder="Infofeld"
                                    onKeyDown={(e) => {if(e.keyCode === 13) {
                                    e.preventDefault();
                                    //this.props.updateCalendarData({dateIndex: this.props.calendarUser.dateIndex, user: this.props.calendarUser.user, userIndex:this.props.calendarUser.userIndex, prop:'view', val: !this.props.calendarUser.user.view});
                                    this.props.updateCalendarData({dateIndex: this.props.calendarUser.dateIndex, user: this.props.calendarUser.user, userIndex:this.props.calendarUser.userIndex, prop:'statusLabel', val: e.target.value});
                                    this.props.updateCalendarData({dateIndex: this.props.calendarUser.dateIndex, user: this.props.calendarUser.user, userIndex:this.props.calendarUser.userIndex, prop:'status', val: 4});
                                    e.target.value = '';
                                    this.props.updateModal({path: 'absence.calendarUser', prop: 'view', val: !this.props.calendarUserModal.view});
                                    this.props.updateCalendarUser({});
                                }}}
                                    />
                                    </button>
                            </div>
                            <div className="small-12 medium-12 large-12">
                                <span>
                                    {this.props.calendarUser.user ? this.props.calendarUser.user.firstNameShort: ''} {this.props.calendarUser.user ? this.props.calendarUser.user.lastName: ''}, {this.props.calendarUser.date} <br/>
                                    Anwesenheit: {this.props.calendarUser.user ? this.getStatusLabel(this.props.calendarUser.user): ''} {this.props.calendarUser.user ? (this.props.calendarUser.user.specialHoliday && '(S)') : ''} {this.props.calendarUser.user ? (this.props.calendarUser.user.overtimeDeduction && '(ü)') : ''}
                                    {' '}{this.props.calendarUser.user ? this.getTitle(this.props.calendarUser.user): ''}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        )
    }

    renderCalendarUserModal() {
        return (
                <div id="calendarUserModal" className="modal visible">
                    <div className="modal-content width-1000">
                        <div className="headline-counter">
                            <span>
                                <i className="fa fa-suitcase" aria-hidden="true"></i> {this.props.calendarUser.user ? this.props.calendarUser.user.firstNameShort: ''} {this.props.calendarUser.user ? this.props.calendarUser.user.lastName: ''}, {this.props.calendarUser.date}
                            </span>
                            <span className="close">
                                <Icon type="close"
                                onClick={(e) => {
                                    e.preventDefault();
                                    this.props.updateModal({path: 'absence.calendarUser', prop: 'view', val: !this.props.calendarUserModal.view});
                                    this.props.updateCalendarUser({});
                                }}
                                />
                            </span>
                        </div>
                        {this.props.calendarUser.user.isInfo && this.renderCalendarUserModalInfoForm()}
                        {!this.props.calendarUser.user.isInfo && this.renderCalendarUserModalForm()}
                    </div>
                </div>
            )
    }

    render() {
        return (
            <div id="main-side" >
                <Breadcrumb path="An-/ & Abwesenheiten">
                    {this.can('an_abwesenheit_von_allen_sehen') &&
                    <div className='visible btn-3'>
                        <FilterDropdown
                            filter={this.props.locationFilter}
                            list={[{label: 'Alle', id: 'receivealllocations'}, ...this.props.allLocations.sort((a, b) => (a.label > b.label) ? 1 : -1)]}
                            itemIdProp={'id'}
                            itemLabelProp={'label'}
                            resetFilterLabel={true}
                            defaultSelectedLabel={'Ort wechseln'}
                            actionToExecute={this.props.loadCalendar}/>
                    </div>}
                    <div className="btn-3 absence-date">
                        <DateInput
                            selected={this.props.absenceDate
                                ? new Date(this.props.absenceDate)
                                : null}
                            onChange={(date) => this.props.updateAbsenceDate(date ? moment(date.toLocaleString('de-DE'), 'DD.MM.YYYY').format('YYYY-MM-DD') : '')}
                        />
                    </div>
                    <div className="btn-3 absence-date">
                        <input type="text" name='test'
                            style={{'maxWidth': '50px'}}
                            value={this.props.absenceWeekFilter || ''}
                            placeholder={'KW'}
                            onChange={(e) => this.props.updateAbsenceWeekFilter(e.target.value)}
                        />
                    </div>
                    <div className="btn-3 absence-date">
                        <input type="text" name='test'
                            value={this.props.userSearch || ''}
                            placeholder={'Suche Mitarbeiter'}
                            onChange={(e) => this.props.updateUserSearch(e.target.value)}
                        />
                    </div>


                </Breadcrumb>

                <main>
                    <div className="content-wrapper inner-ver">
                        <div className="content-wrapper-box">
                            <div className="grid-wrapper">
                                <div className="small-12 medium-12 large-12">
                                    <div className="site-wrapper">
                                        <div className="headline-counter">
                    <span><i className="fa fa-suitcase" aria-hidden="true"></i> An-/ & Abwesenheiten - {this.props.locationFilter.selectedLabel || this.props.currentUser.currentLocationLabel} {this.props.calendarLoading && <i className="fa fa-spinner fa-pulse fa-fw" aria-hidden="true"/>}</span>
                                            <span>{this.props.calendar.length ? this.getWeek('last') : ''}{this.props.calendar.length ? this.props.calendar[0].weeks : ''} {this.props.calendar.length ? this.getWeek('next') : ''}
                                                <div className="btn btn-3">
                                                    <a href="/" onClick={e => {
                                                        e.preventDefault();
                                                        this.props.updateCalendarWeek(moment().format('YYYY-MM-DD'))
                                                    }}>
                                                        <span>Heute</span>
                                                    </a>
                                                </div>
                                            </span>


                                        </div>

                                        <div className="table no-shadow safari_table">
                                            <div className="table-col">
                                                <div className="table-head small" style={{'minWidth': '150px'}}>
                                                    <span></span>
                                                </div>

                                                {this.props.calendar[0].users && this.props.calendar[0].users.filter((user) => {
                                                    const searchIn = this.props.userSearch.toLowerCase().split(' ')
                                                    let foundSearch = false;
                                                    searchIn.forEach(searchInput => {
                                                        if (user.searchName.toLowerCase().includes(searchInput)) {
                                                            foundSearch = true
                                                        }
                                                    })
                                                    return foundSearch;
                                                }).map((employee, index) => (
                                                    <div className={employee.divider === true ? 'table-content-1 black with-divider' : 'table-content-1 black'} key={index}>
                                                        <span>{employee.firstNameShort} {employee.lastName}</span>
                                                    </div>
                                                ))}
                                            </div>
                                            <div ref="absence" className="scroll-x-able">
                                                {this.props.calendar.map((date, dateIndex) => {
                                                        const holidayName = this.getHolidayName(date.dt);

                                                        const headerClass = holidayName.isVacation ? 'table-head sticky small striped' : 'table-head sticky small'
                                                        return (
                                                            <div className="table-col" key={dateIndex}>
                                                            <div className={date.isActive ? 'table-head small active-date': 'table-head small'}>
                                                                <span>{date.date}</span>
                                                            </div>
                                                            <div className={headerClass} style={{top: this.state.offset + 'px'}}>
                                                                <span>{date.date}</span>
                                                                <span className="info">
                                                                    {holidayName.holidayName}
                                                                </span>
                                                            </div>

                                                            <div className="table-content-1 centered">

                                                                {this.props.calendar[dateIndex].users && this.props.calendar[dateIndex].users.filter((user) => {
                                                                        const searchIn = this.props.userSearch.toLowerCase().split(' ')
                                                                        let foundSearch = false;
                                                                        searchIn.forEach(searchInput => {
                                                                            if (user.searchName.toLowerCase().includes(searchInput)) {
                                                                                foundSearch = true
                                                                            }
                                                                        })
                                                                        return foundSearch;
                                                                    }).map((user, userIndex) => (
                                                                    <div key={userIndex} className="absence" onClick={(e) => {
                                                                        if (user.isSpecialAppointment) {
                                                                            // disable editing for Info appointments
                                                                            return;
                                                                        }
                                                                        if (user.status === 2 || user.status === 3 || user.status === 6) {
                                                                            // disable editing for absence sickReport or training appointments
                                                                            return;
                                                                        }
                                                                        if (permissionToEditAbsenceList(this.props.currentUser, user.loc || this.props.currentUser.currentLocation.id, user.isAppointment, user.status)) {
                                                                            this.props.updateCalendarUser({dateIndex: date.dateIndex, user: user, userIndex:user.userIndex, date: date.date});
                                                                            this.props.updateModal({path: 'absence.calendarUser', prop: 'view', val: !this.props.calendarUserModal.view});
                                                                        }}}>
                                                                        <div className={user.divider === true ? 'absence-view with-divider' : 'absence-view'}>
                                                                            {user.isInfo &&
                                                                                <>
                                                                                <span className={holidayName.holiday ? ' info striped' : user.isSpecialAppointment && user.info.length ? 'info info-popup' : 'info'}
                                                                                   onClick={() => {
                                                                                       if (user.isSpecialAppointment && user.info.length) {
                                                                                            this.setSpecialAppointmentPopup(user.userIndex + dateIndex);
                                                                                       }

                                                                                   }}
                                                                                >{!user.isSpecialAppointment &&
                                                                                    crop(user.info, 20)
                                                                                }
                                                                                {user.isSpecialAppointment &&
                                                                                    crop(user.info[0], 20)
                                                                                }
                                                                                {user.isSpecialAppointment && user.info.length > 1 &&
                                                                                    ` +${user.info.length - 1}`
                                                                                }
                                                                                    </span>
                                                                                {user.isSpecialAppointment &&
                                                                                    <span className={ this.state.specialAppointmentPopup === (user.userIndex + dateIndex) ? 'special-appointment-to-long-popup visible' : 'special-appointment-to-long-popup'}>
                                                                                        <b className="fa fa-times close-special-appointment-to-long-popup"
                                                                                            onClick={() => {
                                                                                                this.setSpecialAppointmentPopup(null);
                                                                                            }}
                                                                                        />
                                                                                        {user.info.length && Array.isArray(user.info) &&  user.info.map((info, index) => <b key={index}>{info}<br/></b>)}

                                                                                    </span>
                                                                                }

                                                                                </>
                                                                            }
                                                                            {!user.isInfo &&
                                                                                <span className={holidayName.holiday ? 'striped' : ''}>
                                                                                    {!holidayName.holiday &&
                                                                                        <span title={this.getTitle(user)} className={this.getEmployeeStatusClass(user.status)}>
                                                                                            {this.getStatusLabel(user)} {user.specialHoliday && '(S)'} {user.overtimeDeduction && '(ü)'}
                                                                                        </span>
                                                                                    }

                                                                                </span>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </div>
                                                        )
                                                }

                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    {this.can('an_abwesenheit_von_allen_exportieren') &&
                                            <div>
                                                <br/>
                                                <form action={this.getLink()}
                                                      method="post"
                                                      ref={(ref) => {
                                                          this.downloadForm = ref;
                                                      }}>
                                                    <input type="hidden" name="authTok"
                                                           value={'Bearer ' + this.props.token}
                                                    />
                                                </form>
                                                <IconLink type="download"  onClick={() => this.downloadForm.submit()}> Export XLSX</IconLink>
                                            </div>
                                        }
                                </div>
                            </div>
                        </div>
                    </div>
                    {this.props.calendarUserModal.view && this.renderCalendarUserModal()}

                </main>

                <footer id="footer-main">
                </footer>
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AbsenceRecord);
