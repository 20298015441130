import { put, select, takeLatest } from 'redux-saga/effects';
import getHeaders from 'Sagas/headers';
import { actions, actionTypes, selectors } from '../Redux/index';
import { host } from './host';
import { delay } from 'redux-saga';


function* folderSaga() {
    yield takeLatest(actionTypes.Data.LOAD_CURRENT_FOLDER, function* (action) {
        try {
            const token = yield select(selectors.Ui.token);
            const headers = getHeaders(token);
            let path = host + '/folder/list/';
            let reqBody = {method: "GET", headers: headers}

            if(action.payload.folder.id) {
                path = host + '/folder/show/'+action.payload.folder.id;
            } else {
                path = host + '/folder/list/';
            }
            const result = yield fetch(path, reqBody);
            yield put(actions.Ui.checkLogin(result.status));
            if (result.status !== 406 && result.status !== 408) {
                const currentFolder = yield result.json();
                yield put(actions.Data.updateCurrentFolder(currentFolder));
            }
            yield put(actions.Ui.updateStockLoadingAnimation(false));
        } catch (e) {
            console.log("ERROR", e);
            yield put(actions.Ui.showLoginError(true));
        }
    });
}

function* folderlistSaga() {
    yield takeLatest(actionTypes.Data.LOAD_FOLDER_LIST, function* () {
        try {
            const token = yield select(selectors.Ui.token);
            const headers = getHeaders(token);

            const result = yield fetch(host + '/folder/listall', { method: "GET", headers });
            yield put(actions.Ui.checkLogin(result.status));
            if (result.status !== 406 && result.status !== 408) {
                const folderList = yield result.json();
                yield put(actions.Data.updateFolderList(folderList));
            }
            yield put(actions.Ui.updateStockLoadingAnimation(false));
        } catch (e) {
            console.log("ERROR", e);
            yield put(actions.Ui.showLoginError(true));
        }
    });
}

function* saveFolderSaga() {
    yield takeLatest(actionTypes.Data.SAVE_FOLDER, function* (action) {
        try {
            const token = yield select(selectors.Ui.token);
            const headers = getHeaders(token);
            let method = 'POST';
            if (action.payload.folder.folder.__identity) {
                method = 'PUT'
            }
            const result = yield fetch(host + '/folder', { method: method, headers: headers, body: JSON.stringify(action.payload.folder) });
            yield put(actions.Ui.checkLogin(result.status));
            if (result.status !== 406 && result.status !== 408) {
                yield put(actions.Data.updateFolderToEdit({}));
                yield put(actions.Data.updateModal({path: 'documents.folder', prop: 'view', val: false}));
                yield put(actions.Data.updateDropdown({dropdown: 'dropdown.documents.folder', prop:'selectedLabel', val:''}));
                yield put(actions.Data.updateDropdown({dropdown: 'dropdown.documents.folder', prop:'selectedId', val:''}));
                yield put(actions.Data.updateDropdown({dropdown: 'dropdown.documents.team', prop:'selectedLabel', val:''}));
                yield put(actions.Data.updateDropdown({dropdown: 'dropdown.documents.team', prop:'selectedId', val:''}));
                yield put(actions.Data.loadFolderList(true));
                yield put(actions.Data.loadCurrentFolder({id:action.payload.folder.folder.parent}));
            }
            yield put(actions.Ui.updateStockLoadingAnimation(false));
        } catch (e) {
            console.log("ERROR", e);
            yield put(actions.Ui.showLoginError(true));
        }
    });
}

function* saveDocumentSaga() {
    yield takeLatest(actionTypes.Data.SAVE_DOCUMENT, function* (action) {
        try {
            const token = yield select(selectors.Ui.token);
            const headers = getHeaders(token);
            let method = 'POST'
            if (action.payload.document.document.__identity) {
                method = 'PUT'
            }
            console.log(action.payload.document)
            const result = yield fetch(host + '/document', { method: method, headers: headers, body: JSON.stringify(action.payload.document) });
            yield put(actions.Ui.checkLogin(result.status));
            if (result.status !== 406 && result.status !== 408) {
                yield put(actions.Data.updateDocumentToEdit({}));
                yield put(actions.Data.updateDocumentToEditData({prop: 'newFolder', val: ''}));
                yield put(actions.Data.updateModal({path: 'documents.document', prop: 'view', val: false}));
                yield put(actions.Data.updateDropdown({dropdown: 'dropdown.documents.folder', prop:'selectedLabel', val:''}));
                yield put(actions.Data.updateDropdown({dropdown: 'dropdown.documents.folder', prop:'selectedId', val:''}));
                yield put(actions.Data.updateDropdown({dropdown: 'dropdown.documents.team', prop:'selectedLabel', val:''}));
                yield put(actions.Data.updateDropdown({dropdown: 'dropdown.documents.team', prop:'selectedId', val:''}));
                yield put(actions.Data.loadFolderList(true));
                yield put(actions.Data.loadCurrentFolder({id:action.payload.document.document.parent}));
            }
        } catch (e) {
            yield put(actions.Ui.showLoginError(true));
        }
    });
}

function* documentListSaga() {
    yield takeLatest(actionTypes.Data.LOAD_DOCUMENT_LIST, function* () {
        try {
            const token = yield select(selectors.Ui.token);
            const headers = getHeaders(token);

            const result = yield fetch(host + '/document/list/0', {
                method: "GET",
                headers,
            });
            yield put(actions.Ui.checkLogin(result.status));
            if (result.status !== 406 && result.status !== 408) {
                const list = yield result.json();
                if (list) {
                    yield put(actions.Data.updateDocumentList(list));
                }
            }
        } catch (e) {
            console.log("ERROR", e);
        }
    });
}

function* searchDocumentsSaga() {
    yield takeLatest(actionTypes.Ui.UPDATE_DOCUMENT_SEARCH, function* () {
        try {
            yield delay(300);

            const token = yield select(selectors.Ui.token);
            let documentSearch = yield select(selectors.Ui.documentSearch);
            const headers = getHeaders(token);
            if (documentSearch.length > 2) {
                yield put(actions.Ui.updateStockLoadingAnimation(true));
                const result = yield fetch(host + '/documentsearch/'+documentSearch, {
                    method: "POST",
                    headers,
                });
                yield put(actions.Ui.checkLogin(result.status));
                if (result.status !== 406 && result.status !== 408) {
                    const currentFolder = yield result.json();
                    if (currentFolder) {
                        yield put(actions.Data.updateCurrentFolder(currentFolder));
                    }
                    yield put(actions.Ui.updateStockLoadingAnimation(false));
                }
            }

        } catch (e) {
            console.log("ERROR", e);
        }
    });
}

function* deleteDocumentSaga() {
    yield takeLatest(actionTypes.Data.DELETE_DOCUMENT, function* (action) {
        try {
            const token = yield select(selectors.Ui.token);
            const headers = getHeaders(token);
            const result = yield fetch(host + '/document/'+action.payload.document.id, {
                method: "DELETE",
                headers
            });
            yield put(actions.Ui.checkLogin(result.status));
            if (result.status !== 406 && result.status !== 408) {
                const response = yield result.json();

                if (response) {
                    if (action.payload.document.parent !== null) {
                        yield put(actions.Data.loadCurrentFolder({id: action.payload.document.parent.id}));
                    } else {
                        yield put(actions.Data.loadCurrentFolder(true));
                    }
                } else {
                    console.log('something went wrong on saving user data');
                }
            }
        } catch (e) {
            console.log("ERROR", e);
        }
    });
}

function* deleteFolderSaga() {
    yield takeLatest(actionTypes.Data.DELETE_FOLDER, function* (action) {
        try {
            const token = yield select(selectors.Ui.token);
            const headers = getHeaders(token);
            const result = yield fetch(host + '/folder/'+action.payload.folder.id, {
                method: "DELETE",
                headers
            });

            yield put(actions.Ui.checkLogin(result.status));
            if (result.status !== 406 && result.status !== 408) {
                const response = yield result.json();

                if (response) {
                    if (action.payload.folder.parent !== null) {
                        yield put(actions.Data.loadCurrentFolder({id: action.payload.folder.parent.id}));
                    } else {
                        yield put(actions.Data.loadCurrentFolder(true));
                    }
                } else {
                    console.log('something went wrong on saving user data');
                }
            }
        } catch (e) {
            console.log("ERROR", e);
        }
    });
}

function* saveShortLinkSaga() {
    yield takeLatest(actionTypes.Data.SAVE_SHORT_LINK, function* (action) {
        try {
            const token = yield select(selectors.Ui.token);
            const stateData = yield select(selectors.Data.data);
            const headers = getHeaders(token);

            const result = yield fetch(host + '/documentsl/'+stateData.shortLink.id, { method: "PUT", headers, body: JSON.stringify({shortLink: stateData.shortLink.name}) });
            yield put(actions.Ui.checkLogin(result.status));
            if (result.status !== 406 && result.status !== 408) {
                if (result.status === 409) {
                    alert('Dieser Shortlink wird bereits verwendet.')
                } else {
                    yield put(actions.Data.updateShortLink({id: '', name: '', error: false}));
                    yield put(actions.Data.updateModal({path: 'documents.shortLink', prop: 'view', val: false}));
                    yield put(actions.Data.loadFolderList(true));
                    yield put(actions.Data.loadCurrentFolder(true));
                }

            }
        } catch (e) {
            console.log(e);
        }
    });
}

export default [folderSaga, folderlistSaga, saveFolderSaga, saveDocumentSaga, deleteDocumentSaga, documentListSaga, deleteFolderSaga, saveShortLinkSaga, searchDocumentsSaga];
