import { createAction } from 'redux-actions';
import { handleActions } from '../Utility/HandleActions';
import { $get, $set } from 'plow-js';


const UPDATE_NEWS_TO_EDIT = 'News/UpdateNewsToEdit';
const ADD_NEWS = 'News/AddNews';
const UPDATE_NEWS = 'News/UpdateNews';
const UPDATE_NEWS_LIST = 'News/UpdateNewsList';
const LOAD_NEWS_LIST = 'News/LoadNewsList';
const UPDATE_NEWS_LIST_COUNT = 'News/UpdateNewsListCount';
const DELETE_NEWS = 'News/DeleteNews';
const RESET_STATE = 'News/ResetState';
const UPDATE_PREVIEW = 'News/UpdatePreview';

const actionTypes = {
    UPDATE_NEWS_TO_EDIT,
    ADD_NEWS,
    UPDATE_NEWS,
    UPDATE_NEWS_LIST,
    LOAD_NEWS_LIST,
    UPDATE_NEWS_LIST_COUNT,
    DELETE_NEWS,
    RESET_STATE,
    UPDATE_PREVIEW
};

const updateNewsToEdit = createAction(UPDATE_NEWS_TO_EDIT, (news) => ({news}));
const addNews = createAction(ADD_NEWS, (news) => ({news}));
const updateNews = createAction(UPDATE_NEWS, (news) => ({news}));
const updateNewsList = createAction(UPDATE_NEWS_LIST, (list) => ({list}));
const loadNewsList = createAction(LOAD_NEWS_LIST, (list) => ({list}));
const updateNewsListCount = createAction(UPDATE_NEWS_LIST_COUNT, (value) => ({value}));
const deleteNews = createAction(DELETE_NEWS, (news) => ({news}));
const resetState = createAction(RESET_STATE, (iState) => ({ iState }));
const updatePreview = createAction(UPDATE_PREVIEW, value => ({ value }));

const actions = {
    updateNewsToEdit,
    addNews,
    updateNews,
    updateNewsList,
    loadNewsList,
    updateNewsListCount,
    deleteNews,
    resetState,
    updatePreview
};

const initialState = {
    newsToEdit: {},
    listCount: 0,
    categories:[
        { label: 'Allgemein' },
        { label: 'Personal' },
        { label: 'Sortiment' }
    ],
    list: [
        { 'id': 'defaultId', 'title': '', 'text': '', date: '', 'resourceUri': '' },
        { 'id': 'defaultId', 'title': '', 'text': '', date: '', 'resourceUri': '' },
        { 'id': 'defaultId', 'title': '', 'text': '', date: '', 'resourceUri': '' },
    ],
    preview: false
};

const reducer = handleActions({
    [UPDATE_NEWS_TO_EDIT]: (payload) => oldState => {
        if(payload.news.prop === 'resetObj') {
            return $set('news.newsToEdit', {}, oldState);
        } else {
            return $set('news.newsToEdit.'+payload.news.prop, payload.news.val, oldState);
        }
    },
    [ADD_NEWS]: (payload) => oldState => {
        let list = [...oldState.news.list];
        list.push(payload.news);
        return $set('news.list', list, oldState);
    },
    [UPDATE_NEWS_LIST]: (payload) => oldState => {
        if (payload.list.start === 0) {
            return $set('news.list', payload.list.list, oldState);
        }
        return $set('news.list', [...oldState.news.list, ...payload.list.list], oldState);
    },
    [UPDATE_NEWS_LIST_COUNT]: (payload) => oldState => {
        return $set('news.listCount', payload.value, oldState);
    },
    [UPDATE_NEWS]: (payload) => oldState => {
        let list = [...oldState.news.list].map(news => {
            if (payload.news.id === news.id) {
                return payload.news.news;
            }
            return news;
        });
        return $set('news.list', list, oldState);
    },
    [DELETE_NEWS]: (payload) => oldState => {
        let list = [...oldState.news.list];
        list.splice(list.indexOf(payload.news), 1);
        return $set('news.list', list, oldState);
    },
    [RESET_STATE]: (payload) => oldState => {
        return $set('news', initialState, oldState);
    },
    [UPDATE_PREVIEW]: ({value}) => oldState => {
        return $set('news.preview', value, oldState);
    },
});

const selectors = {
    news: $get('news'),
};

export default {
    actions,
    actionTypes,
    initialState,
    reducer,
    selectors,
};
