import React from 'react';
import { connect } from 'react-redux';
import {actions} from "../Redux";
import Button from "./UI/Button";

class LikesModal extends React.Component {

    render() {
        const { likesModalIsVisible, toggleVisibilityLikesModal , news} = this.props;
        return (
        <div id="likesModal" className={likesModalIsVisible ? 'modal visible' : 'hidden'}>
            <div className="modal-content width-1000">
                <div className="headline-counter">
                    <span>
                        <i className="fa fa-thumbs-o-up" aria-hidden="true"/> {news ? news.title : 'Likes'}
                    </span>
                </div>
                <div className="content-wrapper">
                    <div className="content-wrapper-box">
                        <div className="grid-wrapper">
                            <div className="small-12 medium-12 large-12">
                                <table>
                                    <thead>
                                    <tr>
                                        <th>Benutzer</th>
                                        <th>Datum</th>
                                    </tr>

                                    </thead>
                                    <tbody>
                                    {this.props.likes.length ?
                                        this.props.likes.map(like => (
                                            <tr>
                                                {/*<div className="initials">
                                            <a href="/"
                                               style={{
                                                   backgroundImage: "url(" + like.avatar + ")",
                                                   backgroundPosition: "center",
                                                   borderRadius: "50%",
                                                   backgroundRepeat: "no-repeat",
                                                   backgroundSize: "cover",
                                               }}><span>{like.avatar ? '' : like.shortName}</span>
                                            </a>
                                        </div>
                                        */}
                                                <td >{like.userFullName} </td>
                                                <td><span className="pull-right">{like.dateFormated}</span></td>
                                            </tr>

                                        )) : this.props.loadingAnimation ? <tr>
                                            <td><i
                                                className={'fa fa-spinner fa-pulse fa-fw'}></i></td>
                                            <td></td>
                                        </tr> : <tr>
                                            <td>Noch keine Likes</td>
                                            <td></td>
                                        </tr>
                                    }
                                    </tbody>
                                </table>

                            </div>
                        </div>
                    </div>
                </div>


                <div className="content-wrapper">
                    <div className="content-wrapper-box">
                        <div className="grid-wrapper">
                            <div className="small-12 medium-12 large-12">
                                <Button label="Schließen" inverted onClick={() => toggleVisibilityLikesModal()}/>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        )
    }
}

const mapStateToProps = (state) => ({
    likes: state.data.likes,
    loadingAnimation: state.advertising.loadingAnimation,
});

const mapDispatchToProps = (dispatch) => {
    return {
        loadLikes: (value) => dispatch(actions.Data.likes(value)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LikesModal);
